import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AlertController,
  LoadingController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { Dimensions, ImageTransform } from 'ngx-image-cropper';
import { ICropperConfig } from './interfaces/i-cropper-config';
import { BaseComponent } from '../../../../shared/utils/base.component';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
})
export class CropperComponent extends BaseComponent implements OnInit {
  // #region Properties (12)

  @Output() public file = new EventEmitter<File>();
  @Output() public base64 = new EventEmitter<string>();
  public canvasRotation = 0;
  @Input() public output: 'blob' | 'base64' = 'base64';
  @Input() public config: ICropperConfig = {
    y: 1080,
    x: 1920,
    quality: 80,
    aspectRatio: 9 / 16,
  };
  public containWithinAspectRatio = false;
  public croppedImage: any = '';
  public hideResizeSquares = true;
  public imageChangedEvent: any = '';
  public isBusy = false;
  public rotation = 0;
  public scale = 1;
  public showCropper = false;
  public transform: ImageTransform = {};
  public fileName: string = '';

  // #endregion Properties (12)

  // #region Constructors (1)

  constructor(
    private platform: Platform,
    toastController: ToastController,
    alertController: AlertController,
    loadingController: LoadingController
  ) {
    super(toastController, alertController, loadingController);
    platform.ready().then(() => {
      if (this.platform.is('desktop')) {
        this.hideResizeSquares = false;
      }
    });
  }

  // #endregion Constructors (1)

  // #region Public Accessors (2)

  public get minHeight(): number {
    return this.config.aspectRatio * this.config.y;
  }

  public get minWidth(): number {
    return this.config.aspectRatio * this.config.x;
  }

  // #endregion Public Accessors (2)

  // #region Public Methods (13)

  public ngOnInit() {}

  public onClear(): void {
    this.croppedImage = null;
    this.imageChangedEvent = null;
    this.showCropper = false;
    this.transform = {};
    this.rotation = 0;
    this.canvasRotation = 0;
  }

  public onCropperReady(dimensions: Dimensions): void {
    // console.log('Cropper ready', dimensions);
  }

  public onEmitter(): void {
    if (!this.croppedImage) {
      this.toast('Selecione uma imagem!', '', 'danger', 'middle');
      return;
    }
    if ((this.output = 'base64')) {
      this.base64.emit(this.croppedImage);
    }
    if ((this.output = 'blob')) {
      this.file.emit(this.croppedImage);
    }
    this.onClear();
  }

  public onImageCropped(event: any): void {
    // console.log('onImageCropped', event, 'output', this.output);
    if ((this.output = 'blob')) {
      const file = new File([event?.blob], this.fileName, {
        type: event?.blob?.type,
      });

      this.croppedImage = file || '';
      return;
    }

    this.croppedImage = event.base64 || '';
  }

  public onImageLoaded($e: any): void {
    // console.log('onImageLoaded', $e);
    this.showCropper = true;
  }

  public onLoadImageFailed(): void {
    this.onClear();
    this.toast('Falha ao carregar a imagem!', '', 'danger', 'middle');
  }

  public onLoadImageFromDevice($e: any): void {
    // console.log('imageChangedEvent', $e);

    this.fileName = $e.target?.files[0]?.name;
    this.imageChangedEvent = $e;
  }

  public onRefresh(): void {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
    this.imageChangedEvent = null;
  }

  public onRotateLeft(): void {
    this.canvasRotation--;
    this.onCropperFlipAfterRotate();
  }

  public onRtateRight(): void {
    this.canvasRotation++;
    this.onCropperFlipAfterRotate();
  }

  public onZoomIn(): void {
    this.scale += 0.1;
    this.transform = { ...this.transform, scale: this.scale };
  }

  public onZoomOut(): void {
    this.scale -= 0.1;
    this.transform = { ...this.transform, scale: this.scale };
  }

  // #endregion Public Methods (13)

  // #region Private Methods (1)

  private onCropperFlipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = { ...this.transform, flipH: flippedV, flipV: flippedH };
  }

  // #endregion Private Methods (1)
}
