import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { IMember } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UpdateMemberPhoneDto } from 'src/services/members/dto/update-member-phone.dto';
import { CreateMemberDto } from '../../services/members/dto/create-member.dto';
import { UpdateMemberRuleDto } from '../../services/members/dto/update-member.dto';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';
import { UpdateImageDto } from '../../services/images/dto/update-image.dto';

@Injectable({
  providedIn: 'root',
})
export class ApiMembersService extends ApiBaseService {
  // #region Constructors (1)

  constructor(http: HttpClient) {
    super(http);
  }

  // #endregion Constructors (1)

  // #region Public Methods (11)

  public create(
    createMemberDto: CreateMemberDto
  ): Observable<IEnvelope<IMember>> {
    const apiURL = `${this.baseUrl}/members/create`;
    return this.http
      .post<IEnvelope<IMember>>(apiURL, createMemberDto)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public delete(memberId: string): Observable<void> {
    const apiURL = `${this.baseUrl}/members/${memberId}`;
    return this.http
      .delete<void>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getAll(
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IMember>> {
    const apiURL = `${this.baseUrl}/members?&lastDocId=${lastDocId}&limit=${limit}`;
    return this.http.get<IEnvelopeArray<IMember>>(apiURL).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }
  public getByTag(
    value: string,
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IMember>> {
    const apiURL = `${this.baseUrl}/members/get-by-tag?tag=${value}&lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<IMember>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public getById(memberId: string): Observable<IEnvelope<IMember>> {
    const apiURL = `${this.baseUrl}/members/member-by-id/${memberId}`;
    return this.http.get<IEnvelope<IMember>>(apiURL).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public getByUserId(memberId: string): Observable<IEnvelope<IMember>> {
    const apiURL = `${this.baseUrl}/members/member-by-userid/${memberId}`;
    return this.http.get<IEnvelope<IMember>>(apiURL).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public getIsAdmin(uid: string): Observable<boolean> {
    const apiURL = `${this.baseUrl}/members/is-admin/${uid}`;
    return this.http.get<boolean>(apiURL).pipe(
      map((res) => res),
      catchError(ErrorHandler.handlerError)
    );
  }

  public getMembersByEmail(): Observable<IEnvelopeArray<IMember>> {
    const apiURL = `${this.baseUrl}/members/members-by-email`;
    return this.http.get<IEnvelopeArray<IMember>>(apiURL).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public memberIn(): Observable<IEnvelopeArray<IMember>> {
    const apiUrl = `${this.baseUrl}/members/members-by-phone`;
    return this.http
      .get<IEnvelopeArray<IMember>>(apiUrl)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updatePhone(
    memberId: string,
    obj: UpdateMemberPhoneDto
  ): Observable<IEnvelope<IMember | null>> {
    const apiURL = `${this.baseUrl}/members/${memberId}/phone`;
    return this.http.patch<IEnvelope<IMember | null>>(apiURL, obj).pipe(
      map((res) => {
        return res;
      }),
      catchError(ErrorHandler.handlerError)
    );
  }

  public updatePhoneConfirm(memberId: string): Observable<IEnvelope<IMember>> {
    const apiURL = `${this.baseUrl}/members/${memberId}/phone-confirm`;
    return this.http.patch<IEnvelope<IMember>>(apiURL, {}).pipe(
      map((res) => {
        return res;
      }),
      catchError(ErrorHandler.handlerError)
    );
  }

  public updateRule(memberId: string, obj: UpdateMemberRuleDto) {
    const apiURL = `${this.baseUrl}/members/${memberId}/rule`;
    return this.http.patch<IEnvelope<IMember>>(apiURL, obj).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public updateImage(
    memberUid: string,
    data: UpdateImageDto
  ): Observable<IEnvelope<{ accessToken: string }>> {
    const apiURL = `${this.baseUrl}/members/${memberUid}/image`;
    return this.http
      .patch<IEnvelope<{ accessToken: string }>>(apiURL, data)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  // #endregion Public Methods (11)
}
