import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { IMember } from 'mobyo-interfaces';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiMembersService } from '../../app/api/api-members.service';
import { PayloadService } from '../payload/payload.service';
import { CreateMemberDto } from './dto/create-member.dto';
import { UpdateMemberRuleDto } from './dto/update-member.dto';
import { UpdateImageDto } from '../images/dto/update-image.dto';

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  // #region Properties (6)

  private memberInSubject: BehaviorSubject<IMember[]>;
  private membersSidebarSubject: BehaviorSubject<IMember[]>;
  private membersSubject: BehaviorSubject<IMember[]>;
  private filteredSubject: BehaviorSubject<IMember[]>;
  private selectedMemberSubject: BehaviorSubject<IMember | null>;

  public memberIn$: Observable<IMember[]>;
  public members$: Observable<IMember[]>;
  public filtered$: Observable<IMember[]>;
  public membersSidebar$: Observable<IMember[]>;
  public selectedMember$: Observable<IMember | null>;

  // #endregion Properties (6)

  // #region Constructors (1)

  constructor(
    private readonly apiMembersService: ApiMembersService,
    public payloadService: PayloadService
  ) {
    this.memberInSubject = new BehaviorSubject<IMember[]>([]);
    this.memberIn$ = this.memberInSubject.asObservable();
    this.membersSubject = new BehaviorSubject<IMember[]>([]);
    this.members$ = this.membersSubject.asObservable();
    this.filteredSubject = new BehaviorSubject<IMember[]>([]);
    this.filtered$ = this.filteredSubject.asObservable();
    this.membersSidebarSubject = new BehaviorSubject<IMember[]>([]);
    this.membersSidebar$ = this.membersSidebarSubject.asObservable();
    this.selectedMemberSubject = new BehaviorSubject<IMember | null>(null);
    this.selectedMember$ = this.selectedMemberSubject.asObservable();
  }

  // #endregion Constructors (1)

  // #region Public Methods (9)
  public nextSelectedMember(member: IMember | null): void {
    this.selectedMemberSubject.next(member);
  }
  public create(obj: CreateMemberDto): Observable<IEnvelope<IMember | null>> {
    return this.apiMembersService.create(obj).pipe(
      map((res: IEnvelope<IMember | null>) => {
        if (res.item) {
          const currentMembers = this.membersSubject.getValue();
          const updatedMembers = [...currentMembers, res.item];
          this.membersSubject.next(updatedMembers);

          const currentFiltered = this.filteredSubject.getValue();
          const updatedFiltered = [...currentFiltered, res.item];
          this.filteredSubject.next(updatedFiltered);
        }
        return res;
      })
    );
  }

  public delete(memberId: string): Observable<void> {
    return this.apiMembersService.delete(memberId).pipe(
      map(() => {
        const currentMembers = this.membersSubject.getValue();
        const updatedMembers = currentMembers.filter((i) => i.id !== memberId);
        this.membersSubject.next(updatedMembers);
      })
    );
  }

  public getById(memberId: string): Observable<IEnvelope<IMember>> {
    return this.apiMembersService.getById(memberId);
  }

  public getIsAdmin(uid: string): Observable<boolean> {
    return this.apiMembersService
      .getIsAdmin(uid)
      .pipe(map((res: boolean) => res));
  }

  public getMemberIn(): Observable<IEnvelopeArray<IMember>> {
    return this.apiMembersService.memberIn().pipe(
      map((res: IEnvelopeArray<IMember>) => {
        if (res.items?.length) {
          this.memberInSubject.next(res.items);
        } else {
          this.memberInSubject.next([]);
        }
        return res;
      })
    );
  }

  public getAll(
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IMember>> {
    return this.apiMembersService.getAll(lastDocId, limit).pipe(
      tap((res: IEnvelopeArray<IMember>) => {
        if (res?.items?.length) {
          this.membersSubject.next(res.items);
          this.filteredSubject.next(this.membersSubject.getValue());
        }
        return res;
      })
    );
  }
  public getAllMore(
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IMember>> {
    return this.apiMembersService.getAll(lastDocId, limit).pipe(
      tap((res: IEnvelopeArray<IMember>) => {
        if (res?.items?.length) {
          this.membersSubject.next([
            ...this.membersSubject.getValue(),
            ...res.items,
          ]);
          this.filteredSubject.next(this.membersSubject.getValue());
        }
        return res;
      })
    );
  }
  public getByTag(
    tag: string,
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IMember>> {
    return this.apiMembersService.getByTag(tag, lastDocId, limit).pipe(
      tap((res: IEnvelopeArray<IMember>) => {
        this.filteredSubject.next(res.items);
        return res;
      })
    );
  }
  public getAllSidebar(): Observable<IEnvelopeArray<IMember>> {
    return this.apiMembersService.getAll(null, '30').pipe(
      tap((res: IEnvelopeArray<IMember>) => {
        if (res?.items?.length) {
          this.membersSidebarSubject.next(res.items);
        }
        return res;
      })
    );
  }
  public getMembersByEmail(): Observable<IEnvelopeArray<IMember>> {
    return this.apiMembersService.getMembersByEmail();
  }

  public query(members: IMember[], query: string) {
    let ref = JSON.parse(JSON.stringify(members));
    if (!query) {
      return (ref = _.sortBy(ref, (o: IMember) => o.name));
    }
    ref = ref.filter((item: IMember) => {
      const hasInName = item.name.toLowerCase().indexOf(query) > -1;
      const hasInRule = item.rule.toLowerCase().indexOf(query) > -1;

      return hasInName || hasInRule;
    });
    return (ref = _.sortBy(ref, (o: IMember) => o.name));
  }

  public updateRule(
    memberId: string,
    body: UpdateMemberRuleDto
  ): Observable<IEnvelope<IMember>> {
    return this.apiMembersService.updateRule(memberId, body).pipe(
      map((res: IEnvelope<IMember>) => {
        if (res.item) {
          const currentMembers = this.membersSubject.getValue();
          const updatedMembers = currentMembers.map((member) =>
            member.id === memberId ? res.item : member
          );

          this.membersSubject.next(updatedMembers as IMember[]);
        }
        return res;
      })
    );
  }

  public updateImage(
    memberId: string,
    data: UpdateImageDto
  ): Observable<IEnvelope<{ accessToken: string }>> {
    return this.apiMembersService.updateImage(memberId, data).pipe(
      map((res: IEnvelope<{ accessToken: string }>) => {
        if (res.item) {
          this.payloadService.nextPayload(res.item.accessToken);

          const currentMembers = this.membersSubject.getValue();
          const updatedMembers = currentMembers.map((member) =>
            member.id === memberId ? this.payloadService.payload : member
          );

          this.membersSubject.next(updatedMembers as IMember[]);
        }
        return res;
      })
    );
  }

  public reset() {
    this.memberInSubject.next([]);
    this.membersSubject.next([]);
    this.filteredSubject.next([]);
    this.membersSidebarSubject.next([]);
    this.selectedMemberSubject.next(null);
  }

  // #endregion Public Methods (9)
}
