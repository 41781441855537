import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AddCompanyKeyDto } from 'src/services/company/dto/add-company-key.dto';
import { AddCompanyMessageDto } from 'src/services/company/dto/add-company-message.dto';
import { UpdateCompanyDto } from 'src/services/company/dto/update-company.dto';
import { UpdateDeliveryAreaDto } from 'src/services/company/dto/update-delivery-area.dto';
import { CreateCompanyDto } from '../../services/company/dto/create-company.dto';
// eslint-disable-next-line max-len
import { ECompanyKeys, ICompanySettings } from 'mobyo-interfaces';
import { DesenfilaTaxesDto } from 'src/services/company/dto/desenfila-taxes-dto';
import { CreateOrUpdateCompanySettingsWaServerDto } from '../../services/company/dto/create-wa-server.dto';
import { UpdateCompanyPaymentProviderPaymentMethodDto } from '../../services/company/dto/update-company-payment-providers-payments-method.dto';
import { ICompany } from '../../services/company/interfaces/i-company';
import { ICompanyMobyoApiConfig } from '../../services/company/interfaces/i-company-mobyo-server-api';
import { IDeliveryArea } from '../../services/company/interfaces/i-delivery-area';
import { UpdateImageDto } from '../../services/images/dto/update-image.dto';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiCompaniesService extends ApiBaseService {
  // #region Constructors (1)

  constructor(http: HttpClient) {
    super(http);
  }

  // #endregion Constructors (1)

  // #region Public Methods (9)

  public register(
    company: CreateCompanyDto
  ): Observable<IEnvelope<{ accessToken: string }>> {
    const apiURL = `${this.baseUrl}/companies/register`;
    return this.http
      .post<IEnvelope<{ accessToken: string }>>(apiURL, company)
      .pipe(
        map((body) => body),
        catchError(ErrorHandler.handlerError)
      );
  }

  public addDeliveryArea(data: IDeliveryArea): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/delivery-area`;
    return this.http.post<IEnvelope<ICompany>>(apiURL, data).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public updateDeliveryArea(
    obj: UpdateDeliveryAreaDto
  ): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/update-delivery-area`;
    return this.http.patch<IEnvelope<ICompany>>(apiURL, obj).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public addKey(key: AddCompanyKeyDto): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/key`;
    return this.http.post<IEnvelope<ICompany>>(apiURL, key).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public deleteDeliveryArea(id: string): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/delivery-area/${id}`;
    return this.http
      .delete<IEnvelope<ICompany>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public deleteKey(field: ECompanyKeys): Observable<any> {
    const apiURL = `${this.baseUrl}/companies/key/${field}`;
    return this.http.delete<any>(apiURL).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public getByContainerId(
    containerId: string
  ): Observable<IEnvelopeArray<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/${containerId}/get-by-container-id`;
    return this.http
      .get<IEnvelopeArray<ICompany>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getCurrent(): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/current`;
    return this.http.get<IEnvelope<ICompany>>(apiURL).pipe(
      // map((body) => {
      //     delete body.item.config;
      //     return body;
      // }),
      catchError(ErrorHandler.handlerError)
    );
  }

  public updateCompany(obj: UpdateCompanyDto): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies`;
    return this.http.patch<IEnvelope<ICompany>>(apiURL, obj).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public updateConfig(
    obj: ICompanyMobyoApiConfig
  ): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/config`;
    return this.http.patch<IEnvelope<ICompany>>(apiURL, obj).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }
  public updateImage(obj: UpdateImageDto): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/update-image`;
    return this.http
      .patch<IEnvelope<ICompany>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public updatePaymentMethod(
    obj: UpdateCompanyPaymentProviderPaymentMethodDto
  ): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/update-payment-method`;
    return this.http
      .patch<IEnvelope<ICompany>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public addMessage(
    obj: AddCompanyMessageDto
  ): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/message`;
    return this.http
      .patch<IEnvelope<ICompany>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public deleteMessage(id: string): Observable<any> {
    const apiURL = `${this.baseUrl}/companies/${id}/delete-message`;
    return this.http.delete(apiURL).pipe(catchError(ErrorHandler.handlerError));
  }

  public createOrGetDesenfila(): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/desenfila/create-or-get`;
    return this.http
      .post<IEnvelope<ICompany>>(apiURL, {})
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getMeliPrefix(): Observable<IEnvelopeArray<{ id: string }>> {
    const apiURL = `${this.baseUrl}/companies/desenfila/meli-prefix`;
    return this.http
      .get<IEnvelopeArray<{ id: string }>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public activateMeliDefault(
    taxes: DesenfilaTaxesDto
  ): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/desenfila/active-meli-default`;
    return this.http
      .patch<IEnvelope<any>>(apiURL, taxes)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public deactivateMercadoPago(): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/desenfila/deactive-meli`;
    return this.http
      .patch<IEnvelope<any>>(apiURL, {})
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public createMercadoPagoStore(): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/desenfila/create-mercado-pago-store`;
    return this.http
      .post<IEnvelope<any>>(apiURL, {})
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updateDesenfilaToken(prefix: string): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/desenfila/update-token/${prefix}`;
    return this.http
      .patch<IEnvelope<ICompany>>(apiURL, {})
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updatePixKey(obj: any): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/desenfila/update-pix`;
    return this.http
      .patch<IEnvelope<ICompany>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public createOrGetIugu(): Observable<IEnvelope<ICompany>> {
    const apiURL = `${this.baseUrl}/companies/iugu/create-or-get`;
    return this.http
      .post<IEnvelope<ICompany>>(apiURL, {})
      .pipe(catchError(ErrorHandler.handlerError));
  }

  // public updateCompany(obj: UpdateCompanyDto): Observable<IEnvelope<ICompany>> {
  //     const apiURL = `${this.baseUrl}/companies`;
  //     return this.http.patch<IEnvelope<ICompany>>(apiURL, obj).pipe(
  //         catchError(ErrorHandler.handlerError)
  //     );
  // }
  // #endregion Public Methods (9)

  public getCompanySettings(
    companyId: string
  ): Observable<IEnvelope<ICompanySettings>> {
    const apiURL = `${this.baseUrl}/company-settings/get-by-id/${companyId}`;
    return this.http
      .get<IEnvelope<ICompanySettings>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public createWaServer(
    data: CreateOrUpdateCompanySettingsWaServerDto
  ): Observable<IEnvelope<ICompanySettings>> {
    const apiURL = `${this.baseUrl}/company-settings/wa-server/create`;
    return this.http
      .post<IEnvelope<ICompanySettings>>(apiURL, data)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updateWaServer(
    data: CreateOrUpdateCompanySettingsWaServerDto
  ): Observable<IEnvelope<ICompanySettings>> {
    const apiURL = `${this.baseUrl}/company-settings/wa-server/update`;
    return this.http
      .patch<IEnvelope<ICompanySettings>>(apiURL, data)
      .pipe(catchError(ErrorHandler.handlerError));
  }
}
