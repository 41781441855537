import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageAuthService } from '../../services/storage/local-auth.service';
import { ERouters } from '../../shared/utils/routers-enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(
    public localStorageAuthService: LocalStorageAuthService,
    private readonly router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    // console.log('[TOKENGUARD] PermissionsService.canActivate');

    if (!this.localStorageAuthService.value) {
      // console.log('[TOKENGUARD] BLOCKED => GO TO LOGIN');
      return this.router.navigate([ERouters.login], {
        replaceUrl: true,
      });
    }
    // console.log('[TOKENGUARD] ALLOWED');
    return true;
  }
}
export const tokenGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | boolean
  | UrlTree
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree> =>
  inject(PermissionsService).canActivate(next, state);
