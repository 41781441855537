import { Injectable, signal, WritableSignal } from '@angular/core';
import { factoryEnvelopeArray, IEnvelope } from 'mobyo-interfaces';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { ApiImagesService } from '../../app/api/api-images.service';
import { IStorageFiles } from './interfaces/i-storage-files';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  // #region Properties (1)

  private imagesSignal: WritableSignal<IStorageFiles | null> = signal(null);
  private imagesSubject: BehaviorSubject<IStorageFiles | null>;

  public images$: Observable<IStorageFiles | null>;
  // public filtered$: BehaviorSubject<IStorageFiles> = new BehaviorSubject<IStorageFiles>([]);

  // #endregion Properties (1)

  // #region Constructors (1)

  constructor(private readonly apiImagesService: ApiImagesService) {
    this.imagesSubject = new BehaviorSubject<IStorageFiles | null>(null);
    this.images$ = this.imagesSubject.asObservable();
  }

  public get files(): IStorageFiles | null {
    return this.imagesSignal();
  }

  public setSignal(value: IStorageFiles) {
    this.imagesSignal.set(value);
  }

  public getFiles(
    folderPath: string | null,
    pageToken: string | null,
    limit: string | number
  ): Observable<IStorageFiles> {
    return this.apiImagesService.getFiles(folderPath, pageToken, limit).pipe(
      map((res: IStorageFiles) => {
        // console.log('getFiles res', res);
        if (res) {
          this.imagesSubject.next(res);
          this.setSignal(res);
          // this.filtered$.next(res.items);
        }
        return res;
      })
    );
  }

  public getMoreFiles(
    folderPath: string | null,
    pageToken: string | null,
    limit: string | number
  ): Observable<IStorageFiles> {
    return this.apiImagesService.getFiles(folderPath, pageToken, limit).pipe(
      map((res: IStorageFiles) => {
        const storage = this.imagesSubject.value;
        if (storage && res) {
          const files = [...storage.files.items, ...res.files.items];
          res.files = factoryEnvelopeArray(files);
          this.imagesSubject.next(res);
          this.setSignal(res);
        }
        return res;
      })
    );
  }

  public uploadMedia(data: FormData): Observable<IEnvelope<any>> {
    return this.apiImagesService.uploadMedia(data).pipe(
      tap((res: IEnvelope<any>) => res)
    );
  }

  public uploadFile(url: string, file: File): Observable<any> {
    return this.apiImagesService
      .uploadFile(url, file)
      .pipe(tap((res: any) => res));
  }
}
