import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { IDelivererMobyo } from 'mobyo-interfaces';
import { CreateDelivererDto } from 'src/services/deliverer/dto/create-deliverer.dto';
import { UpdateDelivererDto } from 'src/services/deliverer/dto/update-deliverer.dto';
import { ErrorHandler } from 'src/shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiDeliverersService extends ApiBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<IDelivererMobyo>> {
    const apiURL = `${this.baseUrl}/deliverers?&lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<IDelivererMobyo>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public add(
    deliverer: CreateDelivererDto
  ): Observable<IEnvelope<IDelivererMobyo>> {
    const apiURL = `${this.baseUrl}/deliverers/v2`;
    return this.http
      .post<IEnvelope<IDelivererMobyo>>(apiURL, deliverer)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public delete(deliverymanId: string): Observable<IEnvelope<IDelivererMobyo>> {
    const apiURL = `${this.baseUrl}/deliverers/${deliverymanId}`;
    return this.http
      .delete<IEnvelope<IDelivererMobyo>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public update(
    deliverymanId: string,
    deliverer: UpdateDelivererDto
  ): Observable<IEnvelope<IDelivererMobyo>> {
    const apiURL = `${this.baseUrl}/deliverers/${deliverymanId}`;
    return this.http
      .patch<IEnvelope<IDelivererMobyo>>(apiURL, deliverer)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updateImage(
    deliverymanId: string,
    data: { base64: string }
  ): Observable<IEnvelope<IDelivererMobyo>> {
    const apiURL = `${this.baseUrl}/deliverers/${deliverymanId}/image`;
    return this.http
      .patch<IEnvelope<IDelivererMobyo>>(apiURL, data)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public searchDeliverer(
    lastDocId: string | null,
    limit: number,
    query: string
  ): Observable<IEnvelopeArray<IDelivererMobyo>> {
    const apiURL = `${this.baseUrl}/deliverers/get-by-tag?&lastDocId=${lastDocId}&limit=${limit}&query=${query}`;
    return this.http
      .get<IEnvelopeArray<IDelivererMobyo>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }
}
