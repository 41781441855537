import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope, IResume } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';
import { UpdateResumeDelivererDto } from '../../services/resumes/dto/update-resume-delivery-general';

@Injectable({
  providedIn: 'root',
})
export class ApiResumeService extends ApiBaseService {
  constructor(http: HttpClient) {
    super(http);
  }
  public getResumeDeliverGeneralById(resumeId: string): Observable<IEnvelope<IResume>> {
    const apiURL = `${this.baseUrl}/resume-deliverers/general?resumeId=${resumeId}`;
    return this.http
      .get<IEnvelope<IResume>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public updateResumeDeliverGeneralById(obj: UpdateResumeDelivererDto): Observable<IEnvelope<IResume>> {
    const apiURL = `${this.baseUrl}/resume-deliverers/update`;
    return this.http
      .patch<IEnvelope<IResume>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
}
