import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'customCurrency',
})
@Injectable()
export class CustomCurrencyPipe implements PipeTransform {
  transform(
    value: string,
    locale: string = 'pt-BR',
    currency: string = 'BRL',
    decimalPlaces: number = 2
  ): string {
    // Se o valor for inválido, retorne uma string vazia.
    if (!value) {
      return '';
    }

    let numberValue: number;

    if (typeof value === 'string') {
      // Converter a string em número e dividir por 100 para obter o valor em reais.
      numberValue = parseInt(value, 10) / 100;
    } else {
      numberValue = value;
    }

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(numberValue);
  }
}
