import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { IIFoodImportStatus } from '../../../../services/notifications/interfaces/i-ifood-import-status';

@Component({
  selector: 'app-import-log',
  templateUrl: './import-log.component.html',
  styleUrls: ['./import-log.component.scss'],
})
export class ImportLogComponent implements OnInit {

  @ViewChild(IonModal) public modal!: IonModal;
  public newProducts: any[] = [];
  public alreadyCreated: any[] = [];
  public inconsistency: any[] = [];
  public createdOptions: any[] = [];
  public alreadyCreatedOptions: any[] = [];
  public inconsistencyOptions: any[] = [];

  constructor() { }

  ngOnInit() {}

  public presentModal(info: IIFoodImportStatus) {
    this.newProducts = info?.extraInfo?.newProducts;
    this.alreadyCreated = info?.extraInfo?.alreadyCreated;
    this.inconsistency = info?.extraInfo?.inconsistency;

    this.createdOptions = info?.extraInfo?.createdOptions;
    this.alreadyCreatedOptions = info?.extraInfo?.alreadyCreatedOptions;
    this.inconsistencyOptions = info?.extraInfo?.inconsistencyOptions;
    
    this.modal.present();
  }

}
