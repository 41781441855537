import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { ECompanyKeys, ICompanySettings } from 'mobyo-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiCompaniesService } from '../../app/api/api-companies.service';
import { UpdateImageDto } from '../images/dto/update-image.dto';
import { PayloadService } from '../payload/payload.service';
import { AddCompanyKeyDto } from './dto/add-company-key.dto';
import { AddCompanyMessageDto } from './dto/add-company-message.dto';
import { CreateCompanyDto } from './dto/create-company.dto';
import { CreateOrUpdateCompanySettingsWaServerDto } from './dto/create-wa-server.dto';
import { DesenfilaTaxesDto } from './dto/desenfila-taxes-dto';
import { UpdateCompanyPaymentProviderPaymentMethodDto } from './dto/update-company-payment-providers-payments-method.dto';
import { UpdateCompanyDto } from './dto/update-company.dto';
import { UpdateDeliveryAreaDto } from './dto/update-delivery-area.dto';
import { ICompany } from './interfaces/i-company';
import { ICompanyMobyoApiConfig } from './interfaces/i-company-mobyo-server-api';
import { IDeliveryArea } from './interfaces/i-delivery-area';
import { IDeliveryAreaFixed } from './interfaces/i-delivery-area-fixed';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  // #region Properties (1)

  public company$: BehaviorSubject<ICompany | null> =
    new BehaviorSubject<ICompany | null>(null);
  public companySettings$: BehaviorSubject<ICompanySettings | null> =
    new BehaviorSubject<ICompanySettings | null>(null);
  public prefixes$: BehaviorSubject<{ id: string }[]> = new BehaviorSubject<
    { id: string }[]
  >([]);

  // #endregion Properties (1)

  // #region Constructors (1)

  constructor(
    private readonly paylaodService: PayloadService,
    private readonly apiCompaniesService: ApiCompaniesService
  ) {}
  public getCurrent(): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.getCurrent().pipe(
      map((res: IEnvelope<ICompany>) => {
        if (!res.item) {
          this.company$.next(null);
        } else {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }
  public create(
    obj: CreateCompanyDto
  ): Observable<IEnvelope<{ accessToken: string }>> {
    console.log('chamando createCompany');
    return this.apiCompaniesService.register(obj).pipe(
      map((res: IEnvelope<{ accessToken: string }>) => {
        console.log('onCreateCompany RES', res);
        if (res?.item?.accessToken) {
          this.paylaodService.nextPayload(res.item.accessToken);
        }
        return res;
      })
    );
  }

  public addKey(key: AddCompanyKeyDto) {
    return this.apiCompaniesService.addKey(key).pipe(
      map((res) => {
        this.company$.next(res.item);
        return res;
      })
    );
  }
  public deleteKey(key: ECompanyKeys) {
    return this.apiCompaniesService.deleteKey(key).pipe(
      map((res) => {
        this.company$.next(res.item);
        return res;
      })
    );
  }
  public updateConfig(
    obj: ICompanyMobyoApiConfig
  ): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.updateConfig(obj).pipe(
      map((res) => {
        if (!res.item) {
          this.company$.next(null);
        } else {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }
  public updateImage(obj: UpdateImageDto): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.updateImage(obj).pipe(
      map((res) => {
        if (!res.item) {
          this.company$.next(null);
        } else {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }
  public updatePaymentMethod(
    obj: UpdateCompanyPaymentProviderPaymentMethodDto
  ): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.updatePaymentMethod(obj).pipe(
      map((res) => {
        if (!res.item) {
          this.company$.next(null);
        } else {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }
  public addMessage(
    obj: AddCompanyMessageDto
  ): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.addMessage(obj).pipe(
      map((res) => {
        if (!res.item) {
          this.company$.next(null);
        } else {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }
  public deleteMessage(id: string): Observable<any> {
    return this.apiCompaniesService.deleteMessage(id).pipe(
      map((res) => {
        this.company$.next(res.item);
        return res;
      })
    );
  }
  public updateCompany(obj: UpdateCompanyDto): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.updateCompany(obj).pipe(
      map((res) => {
        if (!res.item) {
          this.company$.next(null);
        } else {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }

  public addDeliveryArea(body: IDeliveryArea): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.addDeliveryArea(body).pipe(
      map((res: IEnvelope<ICompany>) => {
        if (!res.item) {
          this.company$.next(null);
        } else {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }

  public updateDeliveryArea(
    obj: UpdateDeliveryAreaDto
  ): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.updateDeliveryArea(obj).pipe(
      map((res: IEnvelope<ICompany>) => {
        if (!res.item) {
          this.company$.next(null);
        } else {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }
  public deleteDeliveryArea(id: string): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.deleteDeliveryArea(id).pipe(
      map((res) => {
        if (!res.item) {
          this.company$.next(null);
        } else {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }

  public createOrGetDesenfila(): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.createOrGetDesenfila().pipe(
      map((res) => {
        if (res.item) {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }

  public getMeliPrefix(): Observable<IEnvelopeArray<{ id: string }>> {
    return this.apiCompaniesService.getMeliPrefix().pipe(
      map((res: IEnvelopeArray<{ id: string }>) => {
        this.prefixes$.next(res.items);
        return res;
      })
    );
  }

  public ativateMeliDefault(
    taxes: DesenfilaTaxesDto
  ): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.activateMeliDefault(taxes).pipe(
      map((res) => {
        if (res.item) {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }

  public deactivateMercadoPago(): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.deactivateMercadoPago().pipe(
      map((res) => {
        if (res.item) {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }

  public createMercadoPagoStore(): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.createMercadoPagoStore().pipe(
      map((res) => {
        if (res.item) {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }

  public updateDesenfilaToken(prefix: string): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.updateDesenfilaToken(prefix).pipe(
      map((res) => {
        if (res.item) {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }

  public updatePixKey(obj: any): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.updatePixKey(obj).pipe(
      map((res) => {
        if (res.item) {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }

  public createOrGetIugu(): Observable<IEnvelope<ICompany>> {
    return this.apiCompaniesService.createOrGetIugu().pipe(
      map((res) => {
        if (res.item) {
          this.company$.next(res.item);
        }
        return res;
      })
    );
  }

  public getCompanySettings(
    companyId: string
  ): Observable<IEnvelope<ICompanySettings>> {
    return this.apiCompaniesService.getCompanySettings(companyId).pipe(
      map((res) => {
        if (res.item) {
          this.companySettings$.next(res.item);
        }
        return res;
      })
    );
  }

  public createWaServer(
    data: CreateOrUpdateCompanySettingsWaServerDto
  ): Observable<IEnvelope<ICompanySettings>> {
    return this.apiCompaniesService.createWaServer(data).pipe(
      map((res) => {
        if (res.item) {
          this.companySettings$.next(res.item);
        }
        return res;
      })
    );
  }

  public updateWaServer(
    data: CreateOrUpdateCompanySettingsWaServerDto
  ): Observable<IEnvelope<ICompanySettings>> {
    return this.apiCompaniesService.updateWaServer(data).pipe(
      map((res) => {
        if (res.item) {
          this.companySettings$.next(res.item);
        }
        return res;
      })
    );
  }

  // #endregion Constructors (1)
}
