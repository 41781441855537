import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ListImagesComponent } from './list-images.component';
import { SpinnerModule } from '../spinner/spinner.module';
import { SharedModule } from '../../../../shared/shared.module';
import { CropperModule } from '../cropper/cropper.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    SpinnerModule,
    CropperModule,
  ],
  declarations: [ListImagesComponent],
  exports: [ListImagesComponent],
})
export class ListImagesModule {}
