export enum ERouters {
  app = 'app',
  login = 'login',
  storageFiles = 'storage-files',
  checkin = 'checkin',
  updateCredentialEmail = 'update-credential-email',
  createCompany = 'create-company',
  createPassword = 'create-password',
  recovery = 'recovery-password',
  phoneConfirm = 'phone-confirm',
  loginWithPhone = 'login-with-phone',
  register = 'register',
  landingPage = 'landing-page',
  terms = 'terms-of-use',
  checkout = 'checkout',
  checkout2 = 'checkout2',

  myAccount = 'my-account',
  home = 'home',
  customers = 'customers',

  members = 'members',
  editMember = 'members/edit-member',
  createMember = 'members/create-member',

  products = 'products',
  createProduct = 'products/create-product',
  editProduct = 'products/edit-product',

  toppings = 'toppings',
  createTopping = 'toppings/create-topping',
  editTopping = 'toppings/edit-topping',

  questions = 'questions',
  editQuestion = 'questions/edit-question',
  createQuestion = 'questions/create-question',

  menus = 'menus',
  createMenus = 'menus/create-menu',
  editMenus = 'menus/edit-menu',
  importMenu = 'menus/import-menu',

  engines = 'engines',
  vouchers = 'vouchers',
  images = 'images',

  devices = 'devices',
  updateDeviceApp = 'devices/update-device-app',
  updateDeviceCheckout = 'devices/update-device-checkout',

  settings = 'settings',

  configs = 'configs',
  preparationPlaces = 'configs/preparation-places',
  messages = 'configs/messages',
  deliveryArea = 'configs/delivery-area',
  createDeliveryArea = 'configs/delivery-area/create-delivery-area',
  pixel = 'configs/pixel',
  deliverers = 'configs/deliverers',
  delivererOrders = 'configs/deliverers/deliverer-orders',
  addDeliverer = 'configs/deliverers/add-deliverer',
  editDeliverer = 'configs/deliverers/edit-deliverer',
  tokens = 'configs/tokens',
  whatsapp = 'configs/whatsapp',
  mobyoServer = 'configs/mobyo-server',
  pix = 'configs/pix',

  report_deliver = 'report_deliver',
}
