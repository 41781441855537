import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CreateToppingDto } from 'src/services/topping/dto/create-topping.dto';
import { UpdateToppingDto } from 'src/services/topping/dto/update-topping.dto';
import { ITopping } from 'src/services/topping/interfaces/i-topping';
import { ErrorHandler } from 'src/shared/middlewares/error.handler';

import { ApiBaseService } from './api-base.service';
import { UpdateImageDto } from '../../services/images/dto/update-image.dto';

@Injectable({
  providedIn: 'root',
})
export class ApiToppingService extends ApiBaseService {
  // #region Constructors (1)

  constructor(http: HttpClient) {
    super(http);
  }

  // #endregion Constructors (1)

  // #region Public Methods (4)

  public create(obj: CreateToppingDto): Observable<IEnvelope<ITopping | null>> {
    const apiURL = `${this.baseUrl}/v2/products-topping/create`;
    return this.http
      .post<IEnvelope<ITopping | null>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public update(
    toppingId: string,
    obj: UpdateToppingDto
  ): Observable<IEnvelope<ITopping>> {
    const apiURL = `${this.baseUrl}/v2/products-topping/${toppingId}/update`;
    return this.http
      .patch<IEnvelope<ITopping>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public delete(toppingId: string): Observable<void> {
    const apiURL = `${this.baseUrl}/v2/products-topping/${toppingId}/delete`;
    return this.http
      .delete<void>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getAll(
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<ITopping>> {
    const apiURL = `${this.baseUrl}/v2/products-topping/get-all?lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<ITopping>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getByCode(value: string): Observable<IEnvelopeArray<ITopping>> {
    const apiURL = `${this.baseUrl}/v2/products-topping/get-by-code?code=${value}`;
    return this.http
      .get<IEnvelopeArray<ITopping>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getByTag(
    value: string,
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<ITopping>> {
    const apiURL = `${this.baseUrl}/v2/products-topping/get-by-tag?tag=${value}&lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<ITopping>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updateImage(
    toppingId: string,
    obj: UpdateImageDto
  ): Observable<IEnvelope<ITopping>> {
    const apiURL = `${this.baseUrl}/v2/products-topping/update-image/${toppingId}`;
    return this.http
      .patch<IEnvelope<ITopping>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  // #endregion Public Methods (4)
}
