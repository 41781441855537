import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardMask',
})
export class CardMaskPipe implements PipeTransform {
  transform(value: string, type: 'cardNumber' | 'expiration'): string {
    if (!value) {
      return value;
    }

    let maskedValue = '';
    switch (type) {
      case 'cardNumber':
        maskedValue = this.maskCardNumber(value);
        break;
      case 'expiration':
        maskedValue = this.maskExpiration(value);
        break;
      default:
        maskedValue = value;
    }

    return maskedValue;
  }

  private maskCardNumber(value: string): string {
    const cleanValue = value.replace(/\D/g, ''); // remove non-digits
    let masked = '';

    for (let i = 0; i < cleanValue.length; i++) {
      if (i !== 0 && i % 4 === 0) {
        masked += ' ';
      }
      masked += cleanValue.charAt(i);
    }

    return masked;
  }

  private maskExpiration(value: string): string {
    const cleanValue = value.replace(/\D/g, ''); // remove non-digits
    if (cleanValue.length <= 2) {
      return cleanValue;
    }

    return `${cleanValue.substring(0, 2)}/${cleanValue.substring(2)}`;
  }
}
