import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CreateSectionDto } from 'src/services/section/dto/create-section.dto';
import { DeletePictureDto } from 'src/services/section/dto/delete-picture.dto';
import { UpdateSectionPictureDto } from 'src/services/section/dto/update-picture.dto';
import { UpdateSectionDto } from 'src/services/section/dto/update-section.dto';
import { ISection } from 'src/services/section/interfaces/i-section';
import { UpdateSectionPositionsDto } from '../../services/section/dto/update-section-positions.dto';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiSectionService extends ApiBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(
    menuId: string,
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<ISection>> {
    const apiURL = `${this.baseUrl}/v2/menus-sections/${menuId}/get-all?lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<ISection>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public createSection(
    menuId: string,
    obj: CreateSectionDto
  ): Observable<IEnvelope<ISection>> {
    const apiURL = `${this.baseUrl}/v2/menus-sections/${menuId}/create`;
    return this.http
      .post<IEnvelope<ISection>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public deleteSection(menuId: string, sectionId: string): Observable<void> {
    const apiURL = `${this.baseUrl}/v2/menus-sections/${menuId}/${sectionId}/delete-section-of-menu`;
    return this.http
      .delete<void>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updateSection(
    menuId: string,
    sectionId: string,
    obj: UpdateSectionDto
  ): Observable<IEnvelope<ISection>> {
    const apiURL = `${this.baseUrl}/v2/menus-sections/${menuId}/${sectionId}/update`;
    return this.http
      .patch<IEnvelope<ISection>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updateImage(
    menuId: string,
    sectionId: string,
    obj: UpdateSectionPictureDto
  ): Observable<IEnvelope<ISection>> {
    const apiURL = `${this.baseUrl}/v2/menus-sections/${menuId}/${sectionId}/update-image`;
    return this.http
      .patch<IEnvelope<ISection>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public moveSection(
    menuId: string,
    obj: UpdateSectionPositionsDto
  ): Observable<void> {
    const apiURL = `${this.baseUrl}/v2/menus-sections/${menuId}/move-section/`;
    return this.http
      .patch<void>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public deleteImage(
    menuId: string,
    sectionId: string,
    obj: DeletePictureDto
  ): Observable<void> {
    const apiURL = `${this.baseUrl}/v2/menus-sections/${menuId}/${sectionId}/remove-image`;
    return this.http
      .patch<void>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
}
