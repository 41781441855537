import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  // #region Properties (5)

  @Input() public spinnerSize = 64;;
  @Input() public textSize = 12;
  @Input() public title = 'Carregando...';

  // #endregion Properties (5)

  // #region Public Getters And Setters (1)

  // #endregion Public Getters And Setters (1)
}
