// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=file] {
  display: none;
}

#upload-image {
  cursor: pointer;
}

.image-custom-80 {
  cursor: pointer;
  width: 100%;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-style: dashed;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-width: 1px;
  border-radius: 3px;
  border-color: rgba(58, 63, 81, 0.2);
}

ion-card {
  box-shadow: none !important;
  max-width: 603px;
  margin: 0 auto;
}

image-cropper {
  margin: 0 auto;
  height: 100%;
  min-height: 350px;
  max-width: 603px;
  align-items: center;
  justify-content: center;
  --cropper-outline-color: #fff;
  --cropper-overlay-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/routes/components/cropper/cropper.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,eAAA;EACA,WAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,oBAAA;EACA,mDAAA;EACA,iBAAA;EACA,kBAAA;EACA,mCAAA;AACJ;;AAEA;EACI,2BAAA;EACA,gBAAA;EACA,cAAA;AACJ;;AAEA;EACI,cAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;EACA,6BAAA;AACJ","sourcesContent":["input[type=\"file\"] {\r\n    display: none;\r\n}\r\n\r\n#upload-image {\r\n    cursor: pointer;\r\n}\r\n\r\n.image-custom-80 {\r\n    cursor: pointer;\r\n    width: 100%;\r\n    min-height: 350px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin: 0 auto;\r\n    border-style: dashed;\r\n    box-shadow: 0 .125rem .25rem rgba(#000, .075);\r\n    border-width: 1px;\r\n    border-radius: 3px;\r\n    border-color: rgb(58, 63, 81, 0.2);\r\n}\r\n\r\nion-card {\r\n    box-shadow: none !important;\r\n    max-width: 603px;\r\n    margin: 0 auto;\r\n}\r\n\r\nimage-cropper {\r\n    margin: 0 auto;\r\n    height: 100%;\r\n    min-height: 350px;\r\n    max-width: 603px;\r\n    align-items: center;\r\n    justify-content: center;\r\n    --cropper-outline-color: #fff;\r\n    --cropper-overlay-color: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
