import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiDeviceMenuService } from 'src/app/api/api-device-menu.service';
import { IMenu } from '../menus/interfaces/i-menu';
import { ActivateMenuToDeviceDto } from './dto/activate-menu-device.dto';
import { UpdateDeviceMenuPositionsDto } from './dto/update-device-menu-positions.dto';
@Injectable({
  providedIn: 'root',
})
export class DeviceMenuService {
  // #region Properties (1)

  public deviceMenus$: BehaviorSubject<IMenu[]> = new BehaviorSubject<IMenu[]>(
    []
  );
  public selectedMenuId$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  // #endregion Properties (1)

  // #region Constructors (1)

  constructor(private readonly apiDeviceMenuService: ApiDeviceMenuService) {}

  // #endregion Constructors (1)

  // #region Public Methods (5)

  public activate(obj: ActivateMenuToDeviceDto): Observable<IEnvelope<IMenu>> {
    return this.apiDeviceMenuService.activate(obj).pipe(
      map((res: IEnvelope<IMenu>) => {
        this.deviceMenus$.next([...this.deviceMenus$.value, res.item as IMenu]);
        return res;
      })
    );
  }

  public getAll(id: string): Observable<IEnvelopeArray<IMenu>> {
    return this.apiDeviceMenuService.getAll(id).pipe(
      map((res: IEnvelopeArray<IMenu>) => {
        res.items = _.orderBy(res.items, ['index'], ['asc']);
        this.deviceMenus$.next(res.items);
        return res;
      })
    );
  }

  public getById(
    id: string,
    deviceMenuId: string
  ): Observable<IEnvelope<IMenu>> {
    return this.apiDeviceMenuService.getById(id, deviceMenuId);
  }

  public moveMenu(
    id: string,
    obj: UpdateDeviceMenuPositionsDto
  ): Observable<void> {
    return this.apiDeviceMenuService.moveMenu(id, obj);
  }

  public removeMenu(id: string, menuId: string): Observable<void> {
    return this.apiDeviceMenuService.removeMenu(id, menuId).pipe(
      map(() => {
        const menus = this.deviceMenus$.getValue();
        const index = menus.findIndex((m) => m.id === menuId);
        if (index > -1) {
          menus.splice(index, 1);
          this.deviceMenus$.next(menus);
        }
      })
    );
  }

  public reset() {
    this.deviceMenus$.next([]);
    this.selectedMenuId$.next(null);
  }

  // #endregion Public Methods (5)
}
