import { Injectable } from '@angular/core';
import { IEnvelope } from 'mobyo-interfaces';
import { map, Observable } from 'rxjs';
import { ApiAuthService } from '../../app/api/api-auth.service';
import { PayloadService } from '../payload/payload.service';
import { AuthenticateDto } from './dto/auth.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // #region Constructors (1)

  constructor(
    public apiAuthService: ApiAuthService,
    public payloadService: PayloadService
  ) {}

  // #endregion Constructors (1)

  // #region Public Methods (1)

  public getCode(phoneNumber: string): Observable<void> {
    return this.apiAuthService.getCode(phoneNumber);
  }
  public setCode(
    code: string,
    phoneNumber: string
  ): Observable<IEnvelope<{ accessToken: string }>> {
    return this.apiAuthService.setCode(code, phoneNumber).pipe(
      map((res: IEnvelope<{ accessToken: string }>) => {
        if (res?.item?.accessToken) {
          this.payloadService.nextPayload(res.item.accessToken);
        } else {
          this.payloadService.nextPayload(null);
        }
        return res;
      })
    );
  }
  public auth(
    obj: AuthenticateDto
  ): Observable<IEnvelope<{ accessToken: string }>> {
    return this.apiAuthService.auth(obj).pipe(
      map((res: IEnvelope<{ accessToken: string }>) => {
        if (res.item?.accessToken) {
          this.payloadService.nextPayload(res.item.accessToken);
        } else {
          this.payloadService.nextPayload(null);
        }
        return res;
      })
    );
  }

  // #endregion Public Methods (1)
}
