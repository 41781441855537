import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope } from 'mobyo-interfaces';
import { catchError, Observable } from 'rxjs';
import { IStorageFiles } from '../../services/images/interfaces/i-storage-files';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiImagesService extends ApiBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public getFiles(
    folderPath: string | null,
    pageToken: string | null,
    limit: string | number
  ): Observable<IStorageFiles> {
    const apiURL = `${this.baseUrl}/storage-files/get-files?folderPath=${folderPath}&pageToken=${pageToken}&limit=${limit}`;
    return this.http
      .get<IStorageFiles>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public uploadMedia(data: FormData): Observable<IEnvelope<any>> {
    const apiURL = `${this.baseUrl}/storage-files/receive-file`;
    return this.http
      .post<IEnvelope<any>>(apiURL, data)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public uploadFile(signedUrl: string, file: File): Observable<any> {
    return this.http
      .put(signedUrl, file, {
        headers: {
          'Access-Control-Allow-Origin': 'https://app2.mobyo.com.br/',
          'Content-Type': file.type,
        },
      })
      .pipe(catchError(ErrorHandler.handlerError));
  }
}
