import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhoneNumberMaskDirective } from './phone-number-mask';

@NgModule({
    declarations: [
        PhoneNumberMaskDirective,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PhoneNumberMaskDirective,
    ]
})
export class SharedDirectivesModule { }
