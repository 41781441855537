import { Injectable } from '@angular/core';
import { IMember } from 'mobyo-interfaces';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageAuthService } from '../storage/local-auth.service';
import { LocalStorageCredentialsService } from '../storage/local-credentials.service';

@Injectable({
  providedIn: 'root',
})
export class PayloadService {
  // #region Properties (2)

  private payloadSubject: BehaviorSubject<IMember | null>;

  public payload$: Observable<IMember | null>;

  // #endregion Properties (2)

  // #region Constructors (1)

  constructor(
    public localStorageAuthService: LocalStorageAuthService,
    private readonly localStorageCredentialsService: LocalStorageCredentialsService
  ) {
    this.payloadSubject = new BehaviorSubject<IMember | null>(null);
    this.payload$ = this.payloadSubject.asObservable();
  }

  // #endregion Constructors (1)

  // #region Public Getters And Setters (2)

  public get payload(): IMember | null {
    let payload = this.payloadSubject.value;
    if (!payload) {
      const token = this.localStorageAuthService.value;
      if (token) {
        payload = this.decodeJWT(token);
      }
      this.payloadSubject.next(payload || null);
    }
    return this.payloadSubject.value;
  }

  public set payload(value: IMember | null) {
    this.payloadSubject.next(value);
  }

  // #endregion Public Getters And Setters (2)

  // #region Public Methods (1)

  public nextPayload(token: string | null): void {
    if (!token) {
      this.localStorageAuthService.value = '';
      this.localStorageCredentialsService.value = '';
      this.payloadSubject.next(null);
      return;
    }
    this.localStorageAuthService.value = token;
    const payload = this.decodeJWT(token);
    this.payloadSubject.next(payload || null);
  }

  // #endregion Public Methods (1)

  // #region Private Methods (1)

  private decodeJWT(token: string): IMember | null {
    if (!token) {
      return null;
    }
    try {
      const decoded = jwtDecode<IMember>(token);
      if (decoded) {
        return decoded;
      }
      return null;
    } catch (error) {
      console.error('Erro ao decodificar o JWT', error);
      return null;
    }
  }

  // #endregion Private Methods (1)
}
