import { ERouters } from '../../../shared/utils/routers-enum';

const headingMain = {
  text: 'Menu Principal',
  heading: true,
};
const home = {
  text: 'Home',
  link: `${ERouters.app}/${ERouters.home}`,
  icon: 'home',
};
const company = {
  text: 'Minha Conta',
  link: `${ERouters.app}/${ERouters.myAccount}`,
  icon: 'business',
  admin: true,
};
const members = {
  text: 'Membros',
  link: `${ERouters.app}/${ERouters.members}`,
  icon: 'people',
  admin: true,
};
const deliverers = {
  text: 'Entregadores',
  link: `${ERouters.app}/${ERouters.deliverers}`,
  icon: 'bicycle',
};
const customers = {
  text: 'Clientes',
  link: `${ERouters.app}/${ERouters.customers}`,
  icon: 'body',
};
const vouchers = {
  text: 'Cupons',
  link: `${ERouters.app}/${ERouters.vouchers}`,
  svg: 'voucher',
};
const goods = {
  text: 'Mercadorias',
  icon: 'bag',
  subMenu: [
    {
      text: 'Produtos',
      link: `${ERouters.app}/${ERouters.products}`,
    },
    {
      text: 'Complementos',
      link: `${ERouters.app}/${ERouters.toppings}`,
    },
    {
      text: 'Perguntas',
      link: `${ERouters.app}/${ERouters.questions}`,
    },
  ],
};
const reports = {
  text: 'Relatórios',
  icon: 'bar-chart',
  subMenu: [
    {
      text: 'Entregadores',
      icon: 'bicycle',
      link: `${ERouters.app}/${ERouters.report_deliver}`,
    },
  ],
};
const devices = {
  text: 'Aplicativos',
  link: `${ERouters.app}/${ERouters.devices}`,
  icon: 'browsers',
  admin: false,
};
const catalogs = {
  text: 'Catálogos',
  link: `${ERouters.app}/${ERouters.menus}`,
  icon: 'book',
  admin: false,
};
const images = {
  text: 'Imagens',
  link: `${ERouters.app}/${ERouters.images}`,
  icon: 'images',
  admin: false,
};
const engines = {
  text: 'Canais de Vendas',
  link: `${ERouters.app}/${ERouters.engines}`,
  svg: 'engine',
  admin: true,
};
const configs = {
  text: 'Configurações',
  icon: 'settings',
  admin: true,

  subMenu: [
    {
      text: 'Áreas de Entrega',
      link: `${ERouters.app}/${ERouters.deliveryArea}`,
    },
    {
      text: 'Locais de Preparo',
      link: `${ERouters.app}/${ERouters.preparationPlaces}`,
    },
    {
      text: 'Mobyo Server',
      link: `${ERouters.app}/${ERouters.mobyoServer}`,
    },
    {
      text: 'Pix',
      link: `${ERouters.app}/${ERouters.pix}`,
    },
    {
      text: 'Tokens',
      link: `${ERouters.app}/${ERouters.tokens}`,
    },
    {
      text: 'Whatsapp',
      link: `${ERouters.app}/${ERouters.whatsapp}`,
    },
  ],
};

export const sidebarMenu = [
  headingMain,
  home,
  company,
  members,
  // customers,
  deliverers,
  vouchers,
  goods,
  catalogs,
  images,
  devices,
  engines,
  reports,
  configs,
];
