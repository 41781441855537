import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'docTypeMask'
})
export class DocTypeMaskPipe implements PipeTransform {
  transform(value: string, docType: string): string {
    if (!value) {
      return value;
    }

    const cleanedValue = value.replace(/\D/g, ''); // Remover caracteres não numéricos
    let maskedValue = '';

    if (docType === 'CNPJ') {
      if (cleanedValue.length > 0) {
        maskedValue += cleanedValue.slice(0, 2) + '.';
      }
      if (cleanedValue.length > 2) {
        maskedValue += cleanedValue.slice(2, 5) + '.';
      }
      if (cleanedValue.length > 5) {
        maskedValue += cleanedValue.slice(5, 8) + '/';
      }
      if (cleanedValue.length > 8) {
        maskedValue += cleanedValue.slice(8, 12) + '-';
      }
      if (cleanedValue.length > 12) {
        maskedValue += cleanedValue.slice(12);
      }
    } else if (docType === 'CPF') {
      if (cleanedValue.length > 0) {
        maskedValue += cleanedValue.slice(0, 3) + '.';
      }
      if (cleanedValue.length > 3) {
        maskedValue += cleanedValue.slice(3, 6) + '.';
      }
      if (cleanedValue.length > 6) {
        maskedValue += cleanedValue.slice(6, 9) + '-';
      }
      if (cleanedValue.length > 9) {
        maskedValue += cleanedValue.slice(9, 11);
      }
    }

    return maskedValue;
  }
}
