import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiSectionService } from 'src/app/api/api-section.service';
import { CreateSectionDto } from './dto/create-section.dto';
import { DeletePictureDto } from './dto/delete-picture.dto';
import { UpdateSectionPictureDto } from './dto/update-picture.dto';
import { UpdateSectionPositionsDto } from './dto/update-section-positions.dto';
import { UpdateSectionDto } from './dto/update-section.dto';
import { ISection } from './interfaces/i-section';

@Injectable({
  providedIn: 'root',
})
export class SectionService {
  // #region Properties (1)

  public sections$: BehaviorSubject<ISection[]> = new BehaviorSubject<
    ISection[]
  >([]);

  // #endregion Properties (1)

  // #region Constructors (1)

  constructor(private readonly apiSectionService: ApiSectionService) {}

  // #endregion Constructors (1)

  // #region Public Methods (8)

  public createSection(
    menuId: string,
    obj: CreateSectionDto
  ): Observable<IEnvelope<ISection>> {
    return this.apiSectionService.createSection(menuId, obj).pipe(
      map((res: IEnvelope<ISection>) => {
        if (res.item) {
          this.sections$.next([...this.sections$.value, res.item]);
        }
        return res;
      })
    );
  }

  public deleteImage(
    menuId: string,
    sectionId: string,
    obj: DeletePictureDto
  ): Observable<void> {
    return this.apiSectionService.deleteImage(menuId, sectionId, obj);
  }

  public deleteSection(menuId: string, sectionId: string): Observable<void> {
    return this.apiSectionService.deleteSection(menuId, sectionId).pipe(
      map(() => {
        const index = this.sections$.value.findIndex((x) => x.id === sectionId);
        this.sections$.value.splice(index, 1);
        this.sections$.next([...this.sections$.value]);
      })
    );
  }

  public getAll(
    menuId: string,
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<ISection>> {
    return this.apiSectionService.getAll(menuId, lastDocId, limit).pipe(
      map((res: IEnvelopeArray<ISection>) => {
        res.items = _.orderBy(res.items, ['index'], ['asc']);
        this.sections$.next(res.items);
        return res;
      })
    );
  }

  public reset() {
    this.sections$.next([]);
  }

  public updateImage(
    menuId: string,
    sectionId: string,
    obj: UpdateSectionPictureDto
  ): Observable<IEnvelope<ISection>> {
    return this.apiSectionService.updateImage(menuId, sectionId, obj).pipe(
      map((res: IEnvelope<ISection>) => {
        const index = this.sections$.value.findIndex((x) => x.id === sectionId);
        if (res.item) {
          this.sections$.value[index] = res.item;
        }
        this.sections$.next([...this.sections$.value]);
        return res;
      })
    );
  }

  public updateSection(
    menuId: string,
    sectionId: string,
    obj: UpdateSectionDto
  ): Observable<IEnvelope<ISection>> {
    return this.apiSectionService.updateSection(menuId, sectionId, obj).pipe(
      map((res: IEnvelope<ISection>) => {
        const i = this.sections$.value.findIndex((x) => x.id === sectionId);
        if (res.item) {
          this.sections$.value[i] = res.item;
        }
        this.sections$.next([...this.sections$.value]);
        return res;
      })
    );
  }

  public updateSectionsPosition(
    menuId: string,
    obj: UpdateSectionPositionsDto
  ): Observable<void> {
    return this.apiSectionService.moveSection(menuId, obj);
  }

  // #endregion Public Methods (8)
}
