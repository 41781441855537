import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticateDto } from '../../services/auth/dto/auth.dto';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';
@Injectable({
  providedIn: 'root',
})
export class ApiAuthService extends ApiBaseService {
  // #region Constructors (1)

  constructor(http: HttpClient) {
    super(http);
  }

  // #endregion Constructors (1)

  // #region Public Methods (3)

  public auth(
    obj: AuthenticateDto
  ): Observable<IEnvelope<{ accessToken: string }>> {
    const apiUrl = `${this.baseUrl}/members/auth`;
    return this.http
      .post<IEnvelope<{ accessToken: string }>>(apiUrl, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getCode(phoneNumber: string): Observable<void> {
    const apiUrl = `${this.baseUrl}/nati/${phoneNumber}/generate-token`;
    return this.http
      .post<void>(apiUrl, {})
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public setCode(
    code: string,
    phoneNumber: string
  ): Observable<IEnvelope<{ accessToken: string }>> {
    const apiUrl = `${this.baseUrl}/nati/${phoneNumber}/${code}/authenticate-token-jwt`;
    return this.http
      .patch<IEnvelope<{ accessToken: string }>>(apiUrl, {})
      .pipe(catchError(ErrorHandler.handlerError));
  }

  // #endregion Public Methods (3)
}
