import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../../../shared/shared.module';
import { CropperComponent } from './cropper.component';

@NgModule({
  declarations: [CropperComponent],
  imports: [CommonModule, IonicModule, SharedModule],
  exports: [CropperComponent],
})
export class CropperModule {}
