import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { SharedModule } from '../../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutPage } from './layout.page';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { ModalSelectImageModule } from '../routes/components/modal-select-image/modal-select-image.module';
import { ImportLogModule } from '../routes/components/import-log/import-log.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SharedModule,
        ImportLogModule,
        ModalSelectImageModule,
    ],
    declarations: [
        LayoutPage,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        UserblockComponent
    ]
})
export class LayoutPageModule { }
