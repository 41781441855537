import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CompanyService } from '../../services/company/company.service';
import { ERouters } from '../../shared/utils/routers-enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  // #region Constructors (1)

  constructor(
    public companyService: CompanyService,
    private readonly router: Router
  ) {}

  // #endregion Constructors (1)

  // #region Public Methods (1)

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    // console.log('[COMPANY-GUARD] PermissionsService.canActivate');
    const company = this.companyService.company$.value;
    if (company) {
      // console.log('[COMPANY-GUARD] PASS => GO TO ROUTE');
      return true;
    } else {
      // console.log('[COMPANY-GUARD] BLOCKED => GO TO REGISTER');
      return this.router.navigate([ERouters.checkin]);
    }
  }

  // #endregion Public Methods (1)
}
export const companyGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | boolean
  | UrlTree
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree> =>
  inject(PermissionsService).canActivate(next, state);
