import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivateMenuToDeviceDto } from 'src/services/device-menu/dto/activate-menu-device.dto';
import { ErrorHandler } from 'src/shared/middlewares/error.handler';
import { UpdateDeviceMenuPositionsDto } from '../../services/device-menu/dto/update-device-menu-positions.dto';
import { IMenu } from '../../services/menus/interfaces/i-menu';

import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiDeviceMenuService extends ApiBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(
    id: string,
    complete: 'true' | 'false' = 'true'
  ): Observable<IEnvelopeArray<IMenu>> {
    const apiURL = `${this.baseUrl}/devices/menus/${id}/get-all?complete=${complete}`;
    return this.http
      .get<IEnvelopeArray<IMenu>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getById(
    id: string,
    deviceMenuId: string
  ): Observable<IEnvelope<IMenu>> {
    const apiURL = `${this.baseUrl}/devices/menus/${id}/${deviceMenuId}/get-by-id`;
    return this.http
      .get<IEnvelope<IMenu>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public moveMenu(
    id: string,
    obj: UpdateDeviceMenuPositionsDto
  ): Observable<void> {
    const apiURL = `${this.baseUrl}/devices/menus/${id}/move-menu/`;
    return this.http
      .patch<void>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public activate(obj: ActivateMenuToDeviceDto): Observable<IEnvelope<IMenu>> {
    const apiURL = `${this.baseUrl}/devices/menus/link-menu-to-device`;
    return this.http
      .post<IEnvelope<IMenu>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public removeMenu(id: string, menuId: string): Observable<void> {
    const apiURL = `${this.baseUrl}/devices/menus/${id}/${menuId}/delete-menu-of-device`;
    return this.http
      .delete<void>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }
}
