import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';

@Injectable({
    providedIn: 'root',
})
export class GravatarService {
    // #region Properties (1)

    private readonly GRAVATAR_URL = 'https://www.gravatar.com/avatar/';

    // #endregion Properties (1)

    // #region Constructors (1)

    constructor() { }

    // #endregion Constructors (1)

    // #region Public Methods (1)

    /**
     * Generates Gravatar URL for a given email.
     *
     * @param email - The email address
     * @param size - Size of the gravatar image (default is 80)
     * @param defaultImage - Defines the behavior for the default image (like '404', 'mp', 'identicon', 'wavatar', etc.)
     * @returns Gravatar URL
     */
    public generateGravatarURL(email: string | null, defaultImage: '404' | 'mp' | 'identicon' | 'monsterid' | 'wavatar' | 'retro' | 'robohash' | 'blank', size: number = 80): string {
      if (!email) {
            return `${this.GRAVATAR_URL}?s=${size}&d=wavatar`;
        }
        const hash = Md5.hashStr(email.toLowerCase().trim());
        return `${this.GRAVATAR_URL}${hash}?s=${size}&d=${defaultImage}`;
    }

    // #endregion Public Methods (1)
}
