import { Injectable } from '@angular/core';

import { IEnvelope, IEnvelopeArray, IOrder } from 'mobyo-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { ApiOrderService } from '../../app/api/api-order.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public order$: BehaviorSubject<IOrder | null> =
    new BehaviorSubject<IOrder | null>(null);
  public orders$: BehaviorSubject<IOrder[]> = new BehaviorSubject<IOrder[]>([]);

  constructor(private readonly apiOrderService: ApiOrderService) {}

  public getAll(
    lastDocId: string | null,
    limit: number,
    startDate: string,
    endDate: string
  ): Observable<IEnvelopeArray<IOrder>> {
    return this.apiOrderService
      .getAll(lastDocId, limit, startDate, endDate)
      .pipe(
        map((res) => {
          this.orders$.next(res.items);
          return res;
        })
      );
  }

  public getAllByDeliverer(
    dateTime: number,
    delivererId: string
  ): Observable<IEnvelopeArray<IOrder>> {
    return this.apiOrderService.getAllByDeliverer(dateTime, delivererId).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public reset() {
    this.order$.next(null);
    this.orders$.next([]);
  }

  public updateDeliveryFee(
    orderId: string,
    obj: { deliveryFee: number }
  ): Observable<IEnvelope<IOrder>> {
    return this.apiOrderService.updateDeliveryFee(orderId, obj).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
