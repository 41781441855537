import { Injectable } from '@angular/core';
import { IEnvelope } from 'mobyo-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiProductSectionService } from 'src/app/api/api-product-section.service';
import { LinkProdcutSectionDto } from './dto/link.dto';
import { UpdateProductSectionDto } from './dto/update-product-section.dto';
import { UpdateProductsPositionsDto } from './dto/update-products-positions.dto';
import { IProductSection } from './interfaces/i-product-section';

@Injectable({
  providedIn: 'root',
})
export class ProductSectionService {
  public product$: BehaviorSubject<IProductSection | null> =
    new BehaviorSubject<IProductSection | null>(null);

  constructor(
    private readonly apiProductSectionService: ApiProductSectionService
  ) {}

  public link(
    obj: LinkProdcutSectionDto
  ): Observable<IEnvelope<IProductSection>> {
    return this.apiProductSectionService.link(obj);
  }
  public getById(
    menuId: string,
    sectionId: string,
    productId: string
  ): Observable<IEnvelope<IProductSection>> {
    return this.apiProductSectionService
      .getById(menuId, sectionId, productId)
      .pipe(
        map((res: IEnvelope<IProductSection>) => {
          this.product$.next(res.item);
          return res;
        })
      );
  }
  public unlink(
    menuId: string,
    sectionId: string,
    productId: string
  ): Observable<void> {
    return this.apiProductSectionService.remove(menuId, sectionId, productId);
  }
  public update(
    productId: string,
    obj: UpdateProductSectionDto
  ): Observable<IEnvelope<IProductSection>> {
    return this.apiProductSectionService.update(productId, obj).pipe(
      map((res: IEnvelope<IProductSection>) => {
        this.product$.next(res.item);
        return res;
      })
    );
  }
  public updateProductsPosition(
    obj: UpdateProductsPositionsDto
  ): Observable<void> {
    return this.apiProductSectionService.moveProducts(obj);
  }

  public reset() {
    this.product$.next(null);
  }
}
