import { HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';
import { ErrorModel } from './models/error.model';

export class ErrorHandler {
    static handlerError(error: HttpErrorResponse | any) {
        return throwError(ErrorHandler.makeErrorModel(error));
    }
    static makeErrorModel(error: HttpErrorResponse | any) {
        let errorModel: ErrorModel;
        if (error instanceof HttpErrorResponse) {
            errorModel = {
                message: error.error.message,
                status: error.status,
            };

        } else {
            errorModel = {
                message: error.message || error.toString(),
                status: error.status || 0
            };
        }
        return errorModel;
    }
}
