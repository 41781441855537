import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { ApiToppingService } from 'src/api/api-topping.service';
import { ApiToppingService } from 'src/app/api/api-topping.service';
import { CreateToppingDto } from './dto/create-topping.dto';
import { UpdateToppingDto } from './dto/update-topping.dto';
import { ITopping } from './interfaces/i-topping';
import { UpdateImageDto } from '../images/dto/update-image.dto';

@Injectable({
  providedIn: 'root',
})
export class ToppingService {
  public topping$: BehaviorSubject<ITopping | null> =
    new BehaviorSubject<ITopping | null>(null);
  public toppings$: BehaviorSubject<ITopping[]> = new BehaviorSubject<
    ITopping[]
  >([]);
  public selectedTopping$: BehaviorSubject<ITopping | null> =
    new BehaviorSubject<ITopping | null>(null);
  constructor(private readonly apiToppingService: ApiToppingService) {}

  public create(obj: CreateToppingDto): Observable<IEnvelope<ITopping | null>> {
    return this.apiToppingService.create(obj).pipe(
      map((res: IEnvelope<ITopping | null>) => {
        this.topping$.next(res.item);
        const ref = this.topping$;
        if (ref.value) {
          this.toppings$.value.push(...[ref.value]);
        }
        return res;
      })
    );
  }
  public update(
    toppingId: string,
    obj: UpdateToppingDto
  ): Observable<IEnvelope<ITopping>> {
    return this.apiToppingService.update(toppingId, obj).pipe(
      map((res: IEnvelope<ITopping>) => {
        const index = this.toppings$.value.findIndex((x) => x.id === toppingId);
        if (res.item) {
          this.toppings$.value[index] = res.item;
        }
        this.toppings$.next(this.toppings$.value);
        this.selectedTopping$.next(null);
        return res;
      })
    );
  }

  public delete(toppingId: string): Observable<void> {
    return this.apiToppingService.delete(toppingId).pipe(
      map((res: void) => {
        const index = this.toppings$.value.findIndex((x) => x.id === toppingId);
        this.toppings$.value.splice(index, 1);
        this.toppings$.next(this.toppings$.value);
        return res;
      })
    );
  }

  public getAll(
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<ITopping>> {
    return this.apiToppingService.getAll(lastDocId, limit).pipe(
      map((res: IEnvelopeArray<ITopping>) => {
        if (res.items?.length) {
          const ref = this.toppings$.value;
          res.items = _.orderBy(res.items, ['index'], ['asc']);
          ref.push(...res.items);
          this.toppings$.next(ref);
        }
        return res;
      })
    );
  }

  public getByCode(value: string): Observable<IEnvelopeArray<ITopping>> {
    return this.apiToppingService.getByCode(value).pipe(
      map((res: IEnvelopeArray<ITopping>) => {
        const ref = this.toppings$.value;
        res.items = _.orderBy(res.items, ['index'], ['asc']);
        ref.push(...res.items);
        this.toppings$.next(ref);
        return res;
      })
    );
  }
  public getByTag(
    value: string,
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<ITopping>> {
    return this.apiToppingService.getByTag(value, lastDocId, limit).pipe(
      map((res: IEnvelopeArray<ITopping>) => {
        const ref = this.toppings$.value;
        res.items = _.orderBy(res.items, ['index'], ['asc']);
        ref.push(...res.items);
        this.toppings$.next(ref);
        return res;
      })
    );
  }

  public updateImage(
    toppingId: string,
    obj: UpdateImageDto
  ): Observable<IEnvelope<ITopping>> {
    return this.apiToppingService.updateImage(toppingId, obj).pipe(
      map((res: IEnvelope<ITopping>) => {
        if (res.item) {
          const index = this.toppings$.value.findIndex((x) => x.id === toppingId);
          this.toppings$.value[index] = res.item;
          this.toppings$.next(this.toppings$.value);
          this.selectedTopping$.next(res.item);
        }
        return res;
      })
    );
  }

  public reset() {
    this.topping$.next(null);
    this.toppings$.next([]);
    this.selectedTopping$.next(null);
  }
}
