// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-modal {
  --border-radius: 1rem;
  --height: 70%;
}`, "",{"version":3,"sources":["webpack://./src/app/routes/components/import-log/import-log.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,aAAA;AACF","sourcesContent":["ion-modal {\r\n  --border-radius: 1rem;\r\n  --height: 70%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
