import { Injectable } from '@angular/core';
import {
  factoryEnvelope,
  IEnvelope,
  IResume,
  ResumeEntity,
} from 'mobyo-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResumeService } from '../../app/api/api-resume.service';
import { UpdateResumeDelivererDto } from './dto/update-resume-delivery-general';

@Injectable({
  providedIn: 'root',
})
export class ResumesService {
  // #region Properties (1)
  private resumeDefault = new ResumeEntity();
  public resumeDeliveryGeneral$: BehaviorSubject<IResume> =
    new BehaviorSubject<IResume>(this.resumeDefault);

  constructor(public resumeService: ApiResumeService) {}
  public reset() {
    this.resumeDeliveryGeneral$.next(this.resumeDefault);
  }
  public set resumeDeliveryGeneral(value: IResume) {
    this.resumeDeliveryGeneral$.next(value);
  }

  public getResumeDeliverGeneralById(
    tokenId: string
  ): Observable<IEnvelope<IResume>> {
    return this.resumeService.getResumeDeliverGeneralById(tokenId).pipe(
      map((res) => {
        if (res.item) {
          this.resumeDeliveryGeneral = res.item;
          return res;
        }
        return factoryEnvelope(this.resumeDefault);
      })
    );
  }
  public updateResumeDeliverGeneralById(
    obj: UpdateResumeDelivererDto
  ): Observable<void> {
    return this.resumeService.updateResumeDeliverGeneralById(obj).pipe(map((_) => {}));
  }
}
