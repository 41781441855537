import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { ICustomerMember } from 'mobyo-interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Base62ConverterService {
  // #region Properties (1)

  private readonly characters =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  // #endregion Properties (1)

  // #region Public Methods (5)

  public decode(base62Str: string): { customerId: string } {
    const [containerIdBase62, idBase62] = base62Str.split('_');

    const containerId = this.fromBase62(containerIdBase62);
    const customerId = this.fromBase62(idBase62);

    return { customerId };
  }

  public encode(o: ICustomerMember | null): string {
    if (!o || !o.customerId) {
      return '';
    }
    const containerIdBase62 = this.toBase62(`999`);
    const idBase62 = this.toBase62(o.customerId);
    const res = `${containerIdBase62}_${idBase62}`;
    return res;
  }

  public encodeXapp(): string {
    const appId = environment.mobyoAppId;
    const secrets = environment.mobyoSecrets;
    const appIdSecretsBase64 = this.toBase64(`${appId}:${secrets}`);
    return appIdSecretsBase64;
  }

  public toBase62(hexStr: string): string {
    if (!hexStr || typeof hexStr !== 'string') {
      return '';
    }

    let num = BigInt(`0x${hexStr}`);
    let arr: string[] = [];

    while (num > 0) {
      arr.push(this.characters[Number(num % BigInt(62))]);
      num /= BigInt(62);
    }

    return arr.reverse().join('');
  }

  // Base 64
  public toBase64(str: string): string {
    const hasUnicode = /[^\u0000-\u00ff]/.test(str);
    if (hasUnicode) {
      return Buffer.from(str, 'utf-8').toString('base64');
    } else {
      return btoa(str);
    }
  }

  // #endregion Public Methods (5)

  // #region Private Methods (1)

  private fromBase62(base62Str: string): string {
    if (!base62Str || typeof base62Str !== 'string') {
      return '';
    }
    let num = BigInt(0);
    for (let char of base62Str) {
      num = num * BigInt(62) + BigInt(this.characters.indexOf(char));
    }
    return num.toString(16).toUpperCase();
  }

  // #endregion Private Methods (1)
}
