import { IMember } from 'mobyo-interfaces';

export class AuthenticateDto {
  // #region Properties (3)

  public companyId: string;
  public containerId: string;
  public memberId: string;

  // #endregion Properties (3)

  // #region Constructors (1)

  constructor(member: IMember) {
    this.containerId = member.containerId || '';
    this.companyId = member.companyId || '';
    this.memberId = member.id || '';
  }

  // #endregion Constructors (1)
}
