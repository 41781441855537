import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CreateMenuDto } from '../../services/menus/dto/create-menu.dto';
import { DeleteImageDto } from '../../services/menus/dto/delete-image-dto';
import { UpdateMenuDto } from '../../services/menus/dto/update-menu.dto';
import { IMenu } from '../../services/menus/interfaces/i-menu';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';
import { UpdateImageDto } from '../../services/images/dto/update-image.dto';

@Injectable({
  providedIn: 'root',
})
export class ApiMenuService extends ApiBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IMenu>> {
    const apiURL = `${this.baseUrl}/v2/menus/get-all?lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<IMenu>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getByTag(
    value: string,
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IMenu>> {
    const apiURL = `${this.baseUrl}/v2/menus/get-by-tag?tag=${value}&lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<IMenu>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public findBySourceId(sourceId: string): Observable<IEnvelope<IMenu>> {
    const apiURL = `${this.baseUrl}/v2/menus/get-by-source-id/${sourceId}`;
    return this.http
      .get<IEnvelope<IMenu>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public createMenu(obj: CreateMenuDto): Observable<IEnvelope<IMenu>> {
    const apiURL = `${this.baseUrl}/v2/menus/create`;
    return this.http
      .post<IEnvelope<IMenu>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public createImportedMenu(obj: CreateMenuDto): Observable<IEnvelope<IMenu>> {
    const apiURL = `${this.baseUrl}/v2/menus/create-imported`;
    return this.http
      .post<IEnvelope<IMenu>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getByIdWithSections(menuId: string): Observable<IEnvelope<IMenu>> {
    const apiURL = `${this.baseUrl}/v2/menus/${menuId}/by-id-with-sections`;
    return this.http
      .get<IEnvelope<IMenu>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updateMenu(
    menuId: string,
    menu: UpdateMenuDto
  ): Observable<IEnvelope<IMenu>> {
    const apiURL = `${this.baseUrl}/v2/menus/${menuId}/update`;
    return this.http
      .patch<IEnvelope<IMenu>>(apiURL, menu)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updateImage(
    menuId: string,
    base64: UpdateImageDto
  ): Observable<IEnvelope<IMenu>> {
    const apiURL = `${this.baseUrl}/v2/menus/${menuId}/update-menu-image`;
    return this.http
      .patch<IEnvelope<IMenu>>(apiURL, base64)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public deleteImage(
    menuId: string,
    obj: DeleteImageDto
  ): Observable<IEnvelope<IMenu>> {
    const apiURL = `${this.baseUrl}/v2/menus/${menuId}/delete-menu-image`;
    return this.http
      .patch<IEnvelope<IMenu>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public delete(menuId: string): Observable<void> {
    const apiURL = `${this.baseUrl}/v2/menus/${menuId}/delete`;
    return this.http
      .delete<void>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }
}
