import { IMember } from 'mobyo-interfaces';

import { Component, effect, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { CompanyService } from 'src/services/company/company.service';
import { ICompany } from 'src/services/company/interfaces/i-company';
import { EngineService } from '../../../services/engine/engines.service';
import { EEngineTypes } from '../../../services/engine/interfaces/engine-type.enum';
import { GravatarService } from '../../../services/gravatar/gravatar.service';
import { MembersService } from '../../../services/members/members.service';
import { IIFoodImportStatus } from '../../../services/notifications/interfaces/i-ifood-import-status';
import { NotificationService } from '../../../services/notifications/notifications.service';
import { PayloadService } from '../../../services/payload/payload.service';
import { ProductService } from '../../../services/products/products.service';
import { LocalStorageAuthService } from '../../../services/storage/local-auth.service';
import { BaseComponent } from '../../../shared/utils/base.component';
import { sidebarMenu } from './sidebar-menus';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends BaseComponent implements OnInit {
  @Output() public openLog: EventEmitter<any> = new EventEmitter<any>();
  public importInProcess = false;
  public company: ICompany | null;
  public isBusy = true;
  public isLoading = true;
  public members: IMember[] = [];
  public menuItemsDinamic: Array<any>;
  public menus: Array<any> = [];
  public payload: IMember | null = null;
  public photoURL: string;
  public itemsCount = 0;
  public questionCount = 0;

  constructor(
    private readonly sidebarService: SidebarService,
    private readonly membersService: MembersService,
    private readonly payloadService: PayloadService,
    private readonly companyService: CompanyService,
    private readonly gravatarService: GravatarService,
    private readonly engineService: EngineService,
    private readonly notificationService: NotificationService,
    private readonly productService: ProductService,
    private readonly localStorageAuthService: LocalStorageAuthService,
    private readonly router: Router,
    toastController: ToastController,
    alertController: AlertController,
    loadingController: LoadingController
  ) {
    super(toastController, alertController, loadingController);
    sidebarService.addMenu(sidebarMenu);

    effect(() => {
      this.importInProcess = this.notificationService.importing;
    });
  }

  public get menuItems() {
    return this.menuItemsDinamic;
  }

  public get routerLinkActive() {
    return this.router.url;
  }

  public ngOnInit() {
    this.subs.push(
      this.sidebarService.menuItems$.subscribe((res) => (this.menus = res)),
      this.payloadService.payload$.subscribe((res) => {
        this.payload = res;
        if (this.payload && this.members.length) {
          this.getSidebarMembers();
        }
      }),
      this.membersService.membersSidebar$.subscribe(
        (res) => (this.members = res)
      ),
      this.companyService.company$.subscribe((res) => (this.company = res))
    );
    this.onGetColls();
  }

  public onGetIconSvg(icon: string): string {
    switch (icon) {
      case 'voucher':
        return 'assets/imgs/svgs/voucher-white.png';
      case 'engine':
        return 'assets/imgs/svgs/engine-white.png';
      default:
        return '';
    }
  }

  public onGetPhoto(member: IMember): string {
    if (!member) {
      return 'assets/imgs/avatar.png';
    }
    if (!member.photoUrl) {
      this.photoURL = this.gravatarService.generateGravatarURL(
        member.email || '',
        'identicon'
      );
      return this.photoURL;
    }
    return member.photoUrl;
  }

  public onImgError(event: any) {
    event.target.src = 'assets/imgs/avatar.png';
  }

  public onNavigate(link: string) {
    this.router.navigateByUrl(link);
  }

  private getSidebarMembers() {
    this.membersService.getAllSidebar().subscribe();
  }

  private onGetColls(): void {
    this.getSidebarMembers();
    this.onGetEngines();
  }

  private onGetEngines() {
    this.engineService.getAll(null, 100).subscribe((res) => {
      const engine = res.items.find(
        (e) => e.type === EEngineTypes.ifood && e.active
      );

      if (engine) {
        this.registerWebsocket();
        return;
      }

      console.log('Canal de venda ifood não encontrado ou inativo.');
    });
  }

  private registerWebsocket() {
    this.notificationService.connect(this.localStorageAuthService.value);

    const onConnect = this.notificationService.onConnect().subscribe(() => {
      console.log('Conexão websocket estabelecida com sucesso!');
    });

    const listen = this.notificationService
      .listenChannel('ifood-import-status')
      .subscribe({
        next: (res: IIFoodImportStatus) => {
          if (!this.notificationService.importing) {
            this.notificationService.importing = true;
          }

          console.log(res);
          switch (res.status) {
            case 'OK':
              if (res.extraInfo?.itemsCount)
                this.itemsCount = res.extraInfo.itemsCount;
              if (res.extraInfo?.questionCount)
                this.questionCount = res.extraInfo.questionCount;
              break;
            case 'CATEGORY-START':
              this.toast(
                `Importando categoria ${res?.extraInfo.category?.name}`,
                '',
                'success',
                'bottom'
              );
              break;
            case 'CATEGORY-END':
              this.toast(
                `Categoria ${res?.extraInfo.category?.name} importada`,
                '',
                'success',
                'top'
              );
              break;
            case 'FINISH':
              this.productService.getAll(null, '20').subscribe();
              this.finishedImportToast(res);
              break;
            case 'ERROR':
              this.toast(
                'Houve um erro durante a importação: ' + res.extraInfo?.message,
                'Atenção!',
                'danger',
                'bottom'
              );
              break;
            default:
              this.toast(
                'Caso de importação não mapeado',
                'Indistinto',
                'danger',
                'bottom'
              );
              break;
          }
        },
        error: (error) => {
          console.error('Erro ao ouvir evento ifood-import-status:', error);
        },
      });

    const onConnectError = this.notificationService
      .onConnectError()
      .subscribe((error) => {
        console.error('Erro na conexão:', error);
        this.notificationService.importing = false;
        // this.notificationService.disconnect();
      });

    const onDisconnect = this.notificationService
      .onDisconnect()
      .subscribe(() => {
        console.log('Conexão websocket desconectada!');
      });

    if (!this.subs.includes(onConnect)) this.subs.push(onConnect);
    if (!this.subs.includes(onConnectError)) this.subs.push(onConnectError);
    if (!this.subs.includes(onDisconnect)) this.subs.push(onDisconnect);
    if (!this.subs.includes(listen)) this.subs.push(listen);
  }

  private finishedImportToast(res: IIFoodImportStatus) {
    setTimeout(() => {
      if (this.notificationService.importing)
        this.notificationService.importing = false;
    }, 10000);
    this.toastController
      .create({
        cssClass: 'custom-toast',
        message: 'Produtos importados com sucesso!',
        position: 'bottom',
        color: 'success',
        animated: true,
        duration: 30000,
      })
      .then((toast) => {
        toast.buttons = [
          {
            text: 'Auditoria',
            handler: () => {
              this.openLog.emit(res);
              this.notificationService.importing = false;
            },
          },
          {
            text: 'Fechar',
            handler: () => {
              toast.classList.add('custom-toast-dismiss');
              this.notificationService.importing = false;
            },
          },
        ];
        toast.present();
      });
  }
}
