import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { IDelivererMobyo } from 'mobyo-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiDeliverersService } from 'src/app/api/api-deliverers.service';
import { CreateDelivererDto } from './dto/create-deliverer.dto';

@Injectable({
  providedIn: 'root',
})
export class DelivererService {
  public deliverers$: BehaviorSubject<IDelivererMobyo[]> = new BehaviorSubject<
    IDelivererMobyo[]
  >([]);
  public deliverer$: BehaviorSubject<IDelivererMobyo | null> =
    new BehaviorSubject<IDelivererMobyo | null>(null);
  public selectedDeliverer$: BehaviorSubject<IDelivererMobyo | null> =
    new BehaviorSubject<IDelivererMobyo | null>(null);
  constructor(private readonly apiDeliverersService: ApiDeliverersService) {}
  public getAll(
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<IDelivererMobyo>> {
    return this.apiDeliverersService.getAll(lastDocId, limit).pipe(
      map((res: IEnvelopeArray<IDelivererMobyo>) => {
        this.deliverers$.next(res.items);
        return res;
      })
    );
  }
  public add(obj: CreateDelivererDto): Observable<IEnvelope<IDelivererMobyo>> {
    return this.apiDeliverersService.add(obj).pipe(
      map((res: IEnvelope<IDelivererMobyo>) => {
        if (res?.item) {
          const ref = this.deliverers$.value;
          ref.push(res.item);
          this.deliverers$.next(ref);
        }
        return res;
      })
    );
  }
  public delete(delivererId: string): Observable<IEnvelope<IDelivererMobyo>> {
    return this.apiDeliverersService.delete(delivererId);
  }
  public update(
    delivererId: string,
    data: any
  ): Observable<IEnvelope<IDelivererMobyo>> {
    return this.apiDeliverersService.update(delivererId, data).pipe(
      map((res: IEnvelope<IDelivererMobyo>) => {
        if (res?.item) {
          const ref = this.deliverers$.getValue();
          const index = ref.findIndex((f) => f.id === delivererId);
          if (index > -1) {
            ref[index] = res.item;
            this.deliverers$.next(ref);
          } else {
            throw new Error('Ops! 8887');
          }
        }
        return res;
      })
    );
  }
  public updateImage(
    delivererId: string,
    data: { base64: string }
  ): Observable<IEnvelope<IDelivererMobyo>> {
    return this.apiDeliverersService.updateImage(delivererId, data);
  }
  public searchDeliverer(
    lastDocId: string | null,
    limit: number,
    query: string
  ): Observable<IEnvelopeArray<IDelivererMobyo>> {
    return this.apiDeliverersService
      .searchDeliverer(lastDocId, limit, query)
      .pipe(
        map((res: IEnvelopeArray<IDelivererMobyo>) => {
          this.deliverers$.next(res.items);
          return res;
        })
      );
  }
  public reset() {
    this.deliverers$.next([]);
    this.deliverer$.next(null);
    this.selectedDeliverer$.next(null);
  }
}
