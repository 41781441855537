import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.page.html',
  styleUrls: ['./layout.page.scss'],
})
export class LayoutPage implements OnInit {
  public isMenuOpen = false;
  constructor() { }
  onSplitPaneVisible(event: any) {
    this.isMenuOpen = event.detail.visible;
  }
  ngOnInit() {
  }

}
