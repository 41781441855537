import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CreateQuestionDto } from 'src/services/question/dto/create-question.dto';
import { UpdateOptionsDto } from 'src/services/question/dto/update-options.dto';
import { UpdateQuestionDto } from 'src/services/question/dto/update-question.dto';
import { IQuestion } from 'src/services/question/interfaces/i-question';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiQuestionService extends ApiBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<IQuestion>> {
    const apiURL = `${this.baseUrl}/v2/questions/get-all?lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<IQuestion>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public getByTag(
    query: string,
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<IQuestion>> {
    const apiURL = `${this.baseUrl}/v2/questions/${query}/get-by-tag?lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<IQuestion>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public update(
    questionId: string,
    obj: UpdateQuestionDto
  ): Observable<IEnvelope<IQuestion>> {
    const apiURL = `${this.baseUrl}/v2/questions/${questionId}/update`;
    return this.http
      .patch<IEnvelope<IQuestion>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public updateOptions(
    questionId: string,
    obj: UpdateOptionsDto[]
  ): Observable<IEnvelope<IQuestion>> {
    const apiURL = `${this.baseUrl}/v2/questions/${questionId}/update-options`;
    return this.http
      .patch<IEnvelope<IQuestion>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public delete(questionId: string): Observable<IEnvelope<IQuestion>> {
    const apiURL = `${this.baseUrl}/v2/questions/${questionId}/delete`;
    return this.http
      .delete<IEnvelope<IQuestion>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public create(obj: CreateQuestionDto): Observable<IEnvelope<IQuestion>> {
    const apiURL = `${this.baseUrl}/v2/questions/create`;
    return this.http
      .post<IEnvelope<IQuestion>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  // public updateOptions(questionId, obj: UpdateQuestionOptionsDto): Observable<IEnvelope<IQuestion>> {
  //     const apiURL = `${this.apiUrl}/v2/questions/${questionId}/update-options`;
  //     return this.http.post<IEnvelope<IQuestion>>(apiURL, obj).pipe(
  //         catchError(ErrorHandler.handlerError)
  //     );
  // }
}
