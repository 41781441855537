import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Base62ConverterService } from '../../services/base62/base62.service';
import { LocalStorageAuthService } from '../../services/storage/local-auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private readonly localStorageAuthService: LocalStorageAuthService,
    private readonly base62ConverterService: Base62ConverterService
  ) {}
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const bearerToken = this.localStorageAuthService.value;
    const param2 = this.base62ConverterService.encodeXapp();
    const dupReq = req.clone({
      headers: req.headers
        .set('Authorization', `Bearer ${bearerToken || ''}`)
        .set('X-app', param2 || ''),
    });
    return next.handle(dupReq);
  }
}
