import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'phoneMask',
})
export class PhoneMaskPipe implements PipeTransform {
  transform(
    value: string,
    cellPhoneFormat: boolean,
    code: string = '+55'
  ): string {
    // console.log('PhoneMaskPipe => value', value);

    if (!value) {
      return value;
    }

    const cleanedValue = value.replace(/\D/g, ''); // Remover caracteres não numéricos
    let maskedValue = '';
    // Verificar se é um número internacional (Portugal) e ajustar o formato
    if (code === '+351') {
      if (cleanedValue.length === 9) {
        maskedValue = `${cleanedValue.slice(0, 3)} ${cleanedValue.slice(
          3,
          6
        )} ${cleanedValue.slice(6)}`;
      } else {
        // Se o número não tiver 9 dígitos, manter a lógica padrão com código
        maskedValue = `${cleanedValue}`;
      }
    } else {
      if (!cellPhoneFormat) {
        if (cleanedValue.length === 11) {
          maskedValue = `(${cleanedValue.slice(0, 2)}) ${cleanedValue.slice(
            2,
            7
          )}-${cleanedValue.slice(7)}`;
        } else {
          maskedValue = `(${cleanedValue.slice(0, 2)}) ${cleanedValue.slice(
            2,
            6
          )}-${cleanedValue.slice(6)}`;
        }
      } else {
        if (cleanedValue.length > 0) {
          maskedValue += '(' + cleanedValue.slice(0, 2) + ')';
        }
        if (cleanedValue.length > 2) {
          maskedValue += ' ' + cleanedValue.slice(2, 7);
        }
        if (cleanedValue.length > 7) {
          maskedValue += '-' + cleanedValue.slice(7);
        }
      }
    }
    return maskedValue;
  }
}
