import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LinkProductQuestionDto } from 'src/services/product-question/dto/link-question-product.dto';
import IProductQuestion from 'src/services/product-question/interfaces/i-product-question';
import { ErrorHandler } from 'src/shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';

@Injectable({
    providedIn: 'root'
})
export class ApiProductQuestionService extends ApiBaseService {
    constructor(http: HttpClient) {
        super(http);
    }

    public link(obj: LinkProductQuestionDto): Observable<IEnvelope<IProductQuestion>> {
        const apiURL = `${this.baseUrl}/v2/products-questions/link-question-to-product`;
        return this.http.post<IEnvelope<IProductQuestion>>(apiURL, obj).pipe(
            catchError(ErrorHandler.handlerError)
        );
    }

    public delete(menuId: string, sectionId: string, productId: string, id: string): Observable<void> {
        const apiURL = `${this.baseUrl}/v2/products-questions/${menuId}/${sectionId}/${productId}/${id}/delete-of-product`;
        return this.http.delete<void>(apiURL).pipe(
            catchError(ErrorHandler.handlerError)
        );
    }
}
