import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'PostalCodeMask',
})
export class PostalCodeMaskPipe implements PipeTransform {
  // #region Public Methods (1)

  public transform(value: string): string {
    if (!value) {
      return value;
    }

    const cleanedValue = value.replace(/\D/g, ''); // Remove non-numeric characters

    if (cleanedValue.length > 5) {
      return `${cleanedValue.slice(0, 5)}-${cleanedValue.slice(5)}`;
    }

    return cleanedValue;
  }

  // #endregion Public Methods (1)
}
