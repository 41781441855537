import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';
import { CreateEngineDto } from '../../services/engine/dto/create-engine.dto';
import { IEngine } from '../../services/engine/interfaces/i-engine';
import { IUserCodeIFood } from '../../services/engine/interfaces/i-usercode-ifood';

@Injectable({
  providedIn: 'root',
})
export class ApiEngineService extends ApiBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<IEngine>> {
    const apiURL = `${this.baseUrl}/engines?&lastDocId=${lastDocId}&limit=${limit}`;
    return this.http.get<IEnvelopeArray<IEngine>>(apiURL).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public getById(engineId: string): Observable<IEnvelope<IEngine>> {
    const apiURL = `${this.baseUrl}/engines/${engineId}`;
    return this.http.get<IEnvelope<IEngine>>(apiURL).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public getIfoodToken(
    engineId: string
  ): Observable<IEnvelope<IUserCodeIFood>> {
    const apiURL = `${this.baseUrl}/ifood/v1/usercode`;
    return this.http.post<IEnvelope<IUserCodeIFood>>(apiURL, {}).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public create(
    createEngineDto: CreateEngineDto
  ): Observable<IEnvelope<IEngine>> {
    const apiURL = `${this.baseUrl}/engines`;
    return this.http.post<IEnvelope<IEngine>>(apiURL, createEngineDto).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public delete(engineId: string): Observable<void> {
    const apiURL = `${this.baseUrl}/engines/${engineId}`;
    return this.http.delete<void>(apiURL).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public update(engineId: string, data: any): Observable<IEnvelope<IEngine>> {
    const apiURL = `${this.baseUrl}/engines/${engineId}`;
    return this.http.patch<IEnvelope<IEngine>>(apiURL, data).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public deactivated(engineId: string): Observable<IEnvelope<IEngine>> {
    const apiURL = `${this.baseUrl}/engines/${engineId}/status/deactivate`;
    return this.http.patch<IEnvelope<IEngine>>(apiURL, null).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public activated(engineId: string): Observable<IEnvelope<IEngine>> {
    const apiURL = `${this.baseUrl}/engines/${engineId}/status/activate`;
    return this.http.patch<IEnvelope<IEngine>>(apiURL, null).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public setPayment(
    engineId: string,
    paymentId: string
  ): Observable<IEnvelope<IEngine>> {
    const apiURL = `${this.baseUrl}/engines/${engineId}/payment/${paymentId}`;
    return this.http.patch<IEnvelope<IEngine>>(apiURL, {}).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  public deletePayment(
    engineId: string,
    paymentId: string
  ): Observable<IEnvelope<IEngine>> {
    const apiURL = `${this.baseUrl}/engines/${engineId}/payment/${paymentId}`;
    return this.http.delete<IEnvelope<IEngine>>(apiURL, {}).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }
}
