import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CreatePreparationPlaceDto } from 'src/services/preparation-places/dto/create-preparation-place.dto';
import { UpdatePreparationPlaceDto } from 'src/services/preparation-places/dto/update-preparation-place.dto';
import { IPreparationPlace } from 'src/services/preparation-places/interfaces/i-preparation-place';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiPreparationPlacesService extends ApiBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  getAll(
    lastDocId: string | null,
    limit: number,
    orderBy: string
  ): Observable<IEnvelopeArray<IPreparationPlace>> {
    const apiURL = `${this.baseUrl}/production-place?&lastDocId=${lastDocId}&limit=${limit}orderby=${orderBy}`;
    return this.http.get<IEnvelopeArray<IPreparationPlace>>(apiURL).pipe(
      map((body) => body),
      catchError(ErrorHandler.handlerError)
    );
  }

  update(
    preparationPlaceId: string,
    obj: UpdatePreparationPlaceDto
  ): Observable<IEnvelope<IPreparationPlace>> {
    const apiURL = `${this.baseUrl}/production-place/${preparationPlaceId}`;
    return this.http
      .patch<IEnvelope<IPreparationPlace>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  create(
    preparationPlace: CreatePreparationPlaceDto
  ): Observable<IEnvelope<IPreparationPlace>> {
    const apiURL = `${this.baseUrl}/production-place`;
    return this.http
      .post<IEnvelope<IPreparationPlace>>(apiURL, preparationPlace)
      .pipe(catchError(ErrorHandler.handlerError));
  }
}
