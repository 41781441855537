// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.folder-item:hover {
  cursor: pointer;
  text-decoration: underline;
}

.image-card {
  width: 100%;
  max-width: 350px; /* Largura máxima fixa */
  height: 350px; /* Altura máxima fixa */
  margin: auto; /* Centraliza os cards na tela */
  display: flex;
  justify-content: center;
  align-items: center; /* Centraliza o conteúdo vertical e horizontalmente */
}

ion-img {
  width: 100%; /* Garante que todas as imagens tenham a mesma largura */
  height: 100%; /* Garante que todas as imagens tenham a mesma altura */
  object-fit: cover; /* Cobre todo o espaço do card, mantendo a proporção */
  display: block;
}

.cropping {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selected-image-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.fileVideo {
  max-width: 318px !important;
  max-height: 318px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/routes/components/list-images/list-images.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,eAAA;EACA,0BAAA;AAEF;;AACA;EACE,WAAA;EACA,gBAAA,EAAA,wBAAA;EACA,aAAA,EAAA,uBAAA;EACA,YAAA,EAAA,gCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA,EAAA,qDAAA;AAEF;;AACA;EACE,WAAA,EAAA,wDAAA;EACA,YAAA,EAAA,uDAAA;EACA,iBAAA,EAAA,sDAAA;EACA,cAAA;AAEF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AAEF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;AAEF;;AACA;EACE,2BAAA;EACA,4BAAA;AAEF","sourcesContent":[".folder-item:hover {\r\n  cursor: pointer;\r\n  text-decoration: underline;\r\n}\r\n\r\n.image-card {\r\n  width: 100%;\r\n  max-width: 350px; /* Largura máxima fixa */\r\n  height: 350px; /* Altura máxima fixa */\r\n  margin: auto; /* Centraliza os cards na tela */\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center; /* Centraliza o conteúdo vertical e horizontalmente */\r\n}\r\n\r\nion-img {\r\n  width: 100%; /* Garante que todas as imagens tenham a mesma largura */\r\n  height: 100%; /* Garante que todas as imagens tenham a mesma altura */\r\n  object-fit: cover; /* Cobre todo o espaço do card, mantendo a proporção */\r\n  display: block;\r\n}\r\n\r\n.cropping {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n}\r\n\r\n.selected-image-card {\r\n  display: flex;\r\n  justify-content: center;\r\n  flex-direction: column;\r\n  text-align: center;\r\n  align-items: center;\r\n}\r\n\r\n.fileVideo {\r\n  max-width: 318px !important;\r\n  max-height: 318px !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
