import { Injectable } from '@angular/core';
import { ICustomerMobyo } from 'mobyo-interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  public customer$: BehaviorSubject<ICustomerMobyo | null> =
    new BehaviorSubject<ICustomerMobyo | null>(null);
  public customers$: BehaviorSubject<ICustomerMobyo[]> = new BehaviorSubject<
    ICustomerMobyo[]
  >([]);
  public filtered$: BehaviorSubject<ICustomerMobyo[]> = new BehaviorSubject<
    ICustomerMobyo[]
  >([]);

  constructor() {}

  public reset() {
    this.filtered$.next([]);
    this.customers$.next([]);
    this.customer$.next(null);
  }
}
