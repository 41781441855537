import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray, IOrder } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AddOrderItemDto } from '../../services/order/dto/add-item.dto';
import { OrderIndoorModeDto } from '../../services/order/dto/indoor-mode.dto';
import { SetOrderCustomCustomerDto } from '../../services/order/dto/set-order-custom-customer.dto';
import { SetOrderPaymentMethodDto } from '../../services/order/dto/set-order-payment-method-v3.dto';
import { SetOrderTefDto } from '../../services/order/dto/set-order.tef.dto';
import { OrderTakeoutModeDto } from '../../services/order/dto/takeout-mode.dto';
import { OrderUpdateItemQuantityDto } from '../../services/order/dto/update-item-quantity.dto';
import { OrderStatusEnum } from '../../services/order/interfaces/order-status.enum';
import { OrderV3TypeEnum } from '../../services/order/interfaces/order-v3-type.enum';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';

@Injectable({
  providedIn: 'root',
})
export class ApiOrderService extends ApiBaseService {
  // #region Constructors (1)

  constructor(http: HttpClient) {
    super(http);
  }

  // #endregion Constructors (1)

  // #region Public Methods (10)

  public getAll(
    lastDocId: string | null,
    limit: number,
    startDate: string,
    endDate: string
  ): Observable<IEnvelopeArray<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders?lastDocId=${lastDocId}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;
    return this.http
      .get<IEnvelopeArray<IOrder>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public getById(orderId: string): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}`;
    return this.http
      .get<IEnvelope<IOrder>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public getAllByDeliverer(
    dateTime: number,
    delivererId: string
  ): Observable<IEnvelopeArray<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${delivererId}/by-deliverer?dateTime=${dateTime}`;
    return this.http
      .get<IEnvelopeArray<IOrder>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public indoorMode(
    orderId: string,
    obj: OrderIndoorModeDto
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}/set-indoor`;
    return this.http
      .patch<IEnvelope<IOrder>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public insertItem(
    orderId: string,
    obj: AddOrderItemDto
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}/item`;
    return this.http
      .post<IEnvelope<IOrder>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public orderCancel(orderId: string): Observable<void> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}`;
    return this.http
      .delete<void>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public removeItem(
    orderId: string,
    itemId: string
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}/remove-item/${itemId}`;
    return this.http
      .post<IEnvelope<IOrder>>(apiURL, null)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public setNewOrder(): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders`;
    return this.http
      .post<IEnvelope<IOrder>>(apiURL, null)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public createOrderByType(
    type: OrderV3TypeEnum,
    deviceId: string,
    id: string
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${type}/${deviceId}/${id}`;
    return this.http
      .post<IEnvelope<IOrder>>(apiURL, null)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public setPayment(
    orderId: string,
    obj: SetOrderPaymentMethodDto
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}/payment-method`;
    return this.http
      .patch<IEnvelope<IOrder>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public takeoutMode(
    orderId: string,
    obj: OrderTakeoutModeDto
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}/set-takeout`;
    return this.http
      .patch<IEnvelope<IOrder>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updateItemQuantity(
    orderId: string,
    itemId: string,
    obj: OrderUpdateItemQuantityDto
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}/item-quantity/${itemId}`;
    return this.http
      .patch<IEnvelope<IOrder>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updateStatus(
    orderId: string,
    status: OrderStatusEnum
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}/status/${status}`;
    return this.http
      .patch<IEnvelope<IOrder>>(apiURL, {})
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public updateDeliveryFee(
    orderId: string,
    obj: { deliveryFee: number }
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/update/${orderId}/delivery-fee`;
    return this.http
      .patch<IEnvelope<IOrder>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public setCustomCustomer(
    orderId: string,
    obj: SetOrderCustomCustomerDto
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}/set-custom-customer`;
    return this.http
      .patch<IEnvelope<IOrder>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public setOrderPix(
    orderId: string,
    paymentId: string
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}/set-pix/${paymentId}`;
    return this.http
      .post<IEnvelope<IOrder>>(apiURL, {})
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public setOrderTef(
    orderId: string,
    obj: SetOrderTefDto
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}/set-tef`;
    return this.http
      .post<IEnvelope<IOrder>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public removeOrderPaymentMethod(
    orderId: string,
    paymentId: string
  ): Observable<IEnvelope<IOrder>> {
    const apiURL = `${this.baseUrl}/v3/orders/${orderId}/remove-payment-method/${paymentId}`;
    return this.http
      .patch<IEnvelope<IOrder>>(apiURL, null)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  // #endregion Public Methods (10)
}
