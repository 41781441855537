import { Injectable, signal, WritableSignal } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { IProduct } from '../products/interfaces/i-product';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private importing$: WritableSignal<boolean> = signal(false);
  private connected$: WritableSignal<boolean> = signal(false);

  constructor(private readonly socket: Socket) {}

  public get connected(): boolean {
    return this.connected$();
  }

  public set connected(value: boolean) {
    this.connected$.set(value);
  }

  public get importing(): boolean {
    return this.importing$();
  }

  public set importing(value: boolean) {
    this.importing$.set(value);
  }

  connect(token: string): void {
    if (this.connected) {
      return;
    }
    this.socket.ioSocket.io.opts.query = {
      token,
    };
    this.socket.connect();
  }

  /** Método para encerrar a conexão se necessário */
  disconnect(): void {
    this.socket.disconnect();
  }

  /** Escuta o evento de conexão bem-sucedida */
  onConnect(): Observable<any> {
    this.connected = true;
    this.importing = false;
    return this.socket.fromEvent('connect');
  }

  onDisconnect(): Observable<any> {
    this.connected = false;
    this.importing = false;
    return this.socket.fromEvent('disconnect');
  }

  /** Escuta erros na conexão */
  onConnectError(): Observable<any> {
    return this.socket.fromEvent('connect_error');
  }

  listenChannel(channel: string): Observable<any> {
    return this.socket.fromEvent<any>(channel);
  }
}
