import { Injectable } from '@angular/core';
import { IEnvelope } from 'mobyo-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiProductQuestionService } from 'src/app/api/api-product-question.service';
import { LinkProductQuestionDto } from './dto/link-question-product.dto';
import IProductQuestion from './interfaces/i-product-question';

@Injectable({
  providedIn: 'root',
})
export class ProductQuestionService {
  public productQuestion$: BehaviorSubject<IProductQuestion | null> =
    new BehaviorSubject<IProductQuestion | null>(null);

  constructor(
    private readonly apiProductQuestionService: ApiProductQuestionService
  ) {}

  public link(
    obj: LinkProductQuestionDto
  ): Observable<IEnvelope<IProductQuestion>> {
    return this.apiProductQuestionService.link(obj).pipe(
      map((res: IEnvelope<IProductQuestion>) => {
        // console.log(res);
        this.productQuestion$.next(res.item);
        return res;
      })
    );
  }

  public delete(
    menuId: string,
    sectionId: string,
    productId: string,
    id: string
  ): Observable<void> {
    return this.apiProductQuestionService.delete(
      menuId,
      sectionId,
      productId,
      id
    );
  }

  public reset() {
    this.productQuestion$.next(null);
  }
}
