import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UpdateImageDto } from '../../services/images/dto/update-image.dto';
import { IProduct } from '../../services/products/interfaces/i-product';
import { ErrorHandler } from '../../shared/middlewares/error.handler';
import { ApiBaseService } from './api-base.service';
import { UpdateProductDto } from '../../services/products/dto/update-product.dto';
import { CreateProductDto } from '../../services/products/dto/create-product.dto';

@Injectable({
  providedIn: 'root',
})
export class ApiProductService extends ApiBaseService {
  // #region Constructors (1)

  constructor(http: HttpClient) {
    super(http);
  }

  // #endregion Constructors (1)

  // #region Public Methods (4)

  public create(obj: CreateProductDto): Observable<IEnvelope<IProduct>> {
    const apiURL = `${this.baseUrl}/v2/products/create`;
    return this.http
      .post<IEnvelope<IProduct>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getAll(
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IProduct>> {
    const apiURL = `${this.baseUrl}/v2/products/get-all?lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<IProduct>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getAllBySkill(
    skill: string,
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<IProduct>> {
    const apiURL = `${this.baseUrl}/v2/products/get-all-by-skill?skill=${skill}&lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<IProduct>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getByCode(value: string): Observable<IEnvelopeArray<IProduct>> {
    const apiURL = `${this.baseUrl}/v2/products/get-by-code?code=${value}`;
    return this.http
      .get<IEnvelopeArray<IProduct>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getAllByCodes(codes: string[]): Observable<IEnvelopeArray<any>> {
    const apiURL = `${this.baseUrl}/v2/products/get-by-codes`;
    return this.http
      .post<IEnvelopeArray<any>>(apiURL, codes)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public getByTag(
    value: string,
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IProduct>> {
    const apiURL = `${this.baseUrl}/v2/products/get-by-tag?tag=${value}&lastDocId=${lastDocId}&limit=${limit}`;
    return this.http
      .get<IEnvelopeArray<IProduct>>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public update(
    productId: string,
    obj: UpdateProductDto
  ): Observable<IEnvelope<IProduct>> {
    const apiURL = `${this.baseUrl}/v2/products/${productId}/update`;
    return this.http
      .patch<IEnvelope<IProduct>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public changeStatus(productId: string): Observable<IEnvelope<IProduct>> {
    const apiURL = `${this.baseUrl}/v2/products/${productId}/change-status`;
    return this.http
      .patch<IEnvelope<IProduct>>(apiURL, {})
      .pipe(catchError(ErrorHandler.handlerError));
  }

  public updateImage(
    productId: string,
    obj: UpdateImageDto
  ): Observable<IEnvelope<IProduct>> {
    const apiURL = `${this.baseUrl}/v2/products/${productId}/update-image`;
    return this.http
      .patch<IEnvelope<IProduct>>(apiURL, obj)
      .pipe(catchError(ErrorHandler.handlerError));
  }
  public delete(productId: string): Observable<void> {
    const apiURL = `${this.baseUrl}/v2/products/${productId}/delete`;
    return this.http
      .delete<void>(apiURL)
      .pipe(catchError(ErrorHandler.handlerError));
  }

  // #endregion Public Methods (4)
}
