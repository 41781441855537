import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiQuestionService } from 'src/app/api/api-question.service';
import { CreateQuestionDto } from './dto/create-question.dto';
import { UpdateOptionsDto } from './dto/update-options.dto';
import { UpdateQuestionDto } from './dto/update-question.dto';
import { IQuestion } from './interfaces/i-question';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  public questions$: BehaviorSubject<IQuestion[]> = new BehaviorSubject<
    IQuestion[]
  >([]);
  public selectedQuestion$: BehaviorSubject<IQuestion | null> =
    new BehaviorSubject<IQuestion | null>(null);

  constructor(private readonly apiQuestionService: ApiQuestionService) {}
  public getAll(
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<IQuestion>> {
    return this.apiQuestionService.getAll(lastDocId, limit).pipe(
      map((res: IEnvelopeArray<IQuestion>) => {
        const ref = this.questions$.value;
        if (res.items.length) {
          res.items = _.orderBy(res.items, ['index'], ['asc']);
          ref.push(...res.items);
          this.questions$.next(ref);
          return res;
        }
        return res;
      })
    );
  }
  public getByTag(
    tag: string,
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<IQuestion>> {
    return this.apiQuestionService.getByTag(tag, lastDocId, limit).pipe(
      map((res: IEnvelopeArray<IQuestion>) => {
        res.items = _.orderBy(res.items, ['index'], ['asc']);
        this.questions$.next(res.items);
        return res;
      })
    );
  }

  public update(
    questionId: string,
    obj: UpdateQuestionDto
  ): Observable<IEnvelope<IQuestion>> {
    return this.apiQuestionService.update(questionId, obj).pipe(
      map((res: IEnvelope<IQuestion>) => {
        const index = this.questions$.value.findIndex(
          (x) => x.id === questionId
        );
        this.questions$.value[index] = res.item as IQuestion;
        this.questions$.next(this.questions$.value);
        this.selectedQuestion$.next(res.item);
        return res;
      })
    );
  }
  public updateOptions(
    questionId: string,
    obj: UpdateOptionsDto[]
  ): Observable<IEnvelope<IQuestion>> {
    return this.apiQuestionService.updateOptions(questionId, obj).pipe(
      map((res: IEnvelope<IQuestion>) => {
        const index = this.questions$.value.findIndex(
          (x) => x.id === questionId
        );
        this.questions$.value[index] = res.item as IQuestion;
        this.questions$.next(this.questions$.value);
        return res;
      })
    );
  }

  public delete(questionId: string): Observable<IEnvelope<IQuestion>> {
    return this.apiQuestionService.delete(questionId).pipe(
      map((res: IEnvelope<IQuestion>) => {
        const index = this.questions$.value.findIndex(
          (x) => x.id === questionId
        );
        this.questions$.value.splice(index, 1);
        this.questions$.next(this.questions$.value);
        return res;
      })
    );
  }

  public create(obj: CreateQuestionDto): Observable<IEnvelope<IQuestion>> {
    //? product question
    return this.apiQuestionService.create(obj).pipe(
      map((res: IEnvelope<IQuestion>) => {
        this.questions$.next([...this.questions$.value, res.item as IQuestion]);
        return res;
      })
    );
  }

  // public updateOptions(questionId, obj: UpdateQuestionOptionsDto): Observable<IEnvelope<IQuestion>> {
  //     return this.apiQuestionService.updateOptions(questionId, obj).pipe(
  //         map((res: IEnvelope<IQuestion>) => {
  //             const index = this.questions$.value.findIndex(x => x.id === questionId);
  //             this.questions$.value[index] = res.item;
  //             this.questions$.next(this.questions$.value);
  //             return res;
  //         })
  //     );
  // }

  public reset() {
    this.questions$.next([]);
    this.selectedQuestion$.next(null);
  }
}
