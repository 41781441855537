import { Injectable } from '@angular/core';
import { ProductService } from 'src/services/products/products.service';
import { DelivererService } from '../deliverer/deliverer.service';
import { PreparationPlacesService } from '../preparation-places/preparation-places.service';
import { SectionService } from '../section/section.service';
import { ToppingService } from '../topping/topping.service';
import { CustomersService } from '../customers/customers.service';
import { MenusService } from '../menus/menus.service';
import { DeviceMenuService } from '../device-menu/device-menu.service';
import { QuestionService } from '../question/question.service';
import { ProductSectionService } from '../product-section/product-section.service';
import { ProductQuestionService } from '../product-question/product-question.service';
import { OrderService } from '../order/order.service';
import { NotificationService } from '../notifications/notifications.service';
import { ResumesService } from '../resumes/resumes.service';

@Injectable({
  providedIn: 'root',
})
export class ResetService {
  constructor(
    private readonly toppingService: ToppingService,
    private readonly sectionService: SectionService,
    private readonly preparationPlacesService: PreparationPlacesService,
    private readonly customersService: CustomersService,
    private readonly delivererService: DelivererService,
    private readonly productService: ProductService,
    private readonly menusService: MenusService,
    private readonly deviceMenuService: DeviceMenuService,
    private readonly questionService: QuestionService,
    private readonly productSectionService: ProductSectionService,
    private readonly productQuestionService: ProductQuestionService,
    private readonly orderService: OrderService,
    private readonly notificationService: NotificationService,
    private readonly resumesService: ResumesService,
  ) {}

  public resetAllServices() {
    this.toppingService.reset();
    this.sectionService.reset();
    this.preparationPlacesService.reset();
    this.customersService.reset();
    this.delivererService.reset();
    this.productService.reset();
    this.menusService.reset();
    this.deviceMenuService.reset();
    this.questionService.reset();
    this.productSectionService.reset();
    this.productQuestionService.reset();
    this.resumesService.reset();
    this.orderService.reset();
    this.notificationService.importing = false;
    this.notificationService.disconnect();
  }
}
