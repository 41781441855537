import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiPreparationPlacesService } from 'src/app/api/api-preparation-places.service';
import { CreatePreparationPlaceDto } from './dto/create-preparation-place.dto';
import { UpdatePreparationPlaceDto } from './dto/update-preparation-place.dto';
import { IPreparationPlace } from './interfaces/i-preparation-place';

@Injectable({
  providedIn: 'root',
})
export class PreparationPlacesService {
  // #region Properties (1)

  public preparationPlaces$: BehaviorSubject<IPreparationPlace[]> =
    new BehaviorSubject<IPreparationPlace[]>([]);

  // #endregion Properties (1)

  // #region Constructors (1)

  constructor(
    private readonly apiPreparationPlaceService: ApiPreparationPlacesService
  ) {}

  // #endregion Constructors (1)

  // #region Public Methods (4)

  public create(
    preparationPlace: CreatePreparationPlaceDto
  ): Observable<IEnvelope<IPreparationPlace>> {
    return this.apiPreparationPlaceService.create(preparationPlace).pipe(
      map((res) => {
        const ref = this.preparationPlaces$.value;
        ref.push(res.item as IPreparationPlace);
        this.preparationPlaces$.next(ref);
        return res;
      })
    );
  }

  public getAll(
    lastDocId: string | null,
    limit: number,
    orderBy: string
  ): Observable<IEnvelopeArray<IPreparationPlace>> {
    return this.apiPreparationPlaceService
      .getAll(lastDocId, limit, orderBy)
      .pipe(
        map((res) => {
          if (res.items) {
            const ref = this.preparationPlaces$.value;
            ref.push(...res.items);
            this.preparationPlaces$.next(ref);
            return res;
          } else {
            return res;
          }
        })
      );
  }

  public update(
    preparationPlaceId: string,
    obj: UpdatePreparationPlaceDto
  ): Observable<IEnvelope<IPreparationPlace>> {
    return this.apiPreparationPlaceService.update(preparationPlaceId, obj).pipe(
      map((res) => {
        const index = this.preparationPlaces$.value.findIndex(
          (x) => x.id === preparationPlaceId
        );
        this.preparationPlaces$.value[index] = res.item as IPreparationPlace;
        this.preparationPlaces$.next(this.preparationPlaces$.value);
        return res;
      })
    );
  }

  public reset() {
    this.preparationPlaces$.next([]);
  }

  // #endregion Public Methods (4)
}
