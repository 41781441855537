import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEngineService } from '../../app/api/api-engines.service';
import { CreateEngineDto } from './dto/create-engine.dto';
import { UpdateEngineDto } from './dto/update-engine.dto';
import { IEngine } from './interfaces/i-engine';

@Injectable({
  providedIn: 'root',
})
export class EngineService {
  // #region Properties (1)

  public engines$: BehaviorSubject<IEngine[]> = new BehaviorSubject<IEngine[]>(
    []
  );
  public engine$: BehaviorSubject<IEngine | null> =
    new BehaviorSubject<IEngine | null>(null);

  // #endregion Properties (1)

  // #region Constructors (1)

  constructor(private readonly apiEngineService: ApiEngineService) {}
  public getAll(
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<IEngine>> {
    return this.apiEngineService.getAll(lastDocId, limit).pipe(
      map((res: IEnvelopeArray<IEngine>) => {
        this.engines$.next(res.items);
        return res;
      })
    );
  }
  public getById(engineId: string): Observable<IEnvelope<IEngine>> {
    return this.apiEngineService.getById(engineId);
  }
  public create(obj: CreateEngineDto) {
    return this.apiEngineService.create(obj).pipe(
      map((res: IEnvelope<IEngine>) => {
        if (res.item) {
          this.engines$.value.push(res.item);
          this.engines$.next(this.engines$.value);
        }
        return res;
      })
    );
  }
  public delete(id: string): Observable<void> {
    return this.apiEngineService.delete(id).pipe(
      map(() => {
        const index = this.engines$.value.findIndex((item) => item.id === id);
        if (index > -1) {
          this.engines$.value.splice(index, 1);
          this.engines$.next(this.engines$.value);
        }
      })
    );
  }
  public update(
    id: string,
    obj: UpdateEngineDto
  ): Observable<IEnvelope<IEngine>> {
    return this.apiEngineService.update(id, obj).pipe(
      map((res: IEnvelope<IEngine>) => {
        if (res.item) {
          const index = this.engines$.value.findIndex((item) => item.id === id);
          if (index > -1) {
            this.engines$.value[index] = res.item;
            this.engines$.next(this.engines$.value);
          }
        }
        return res;
      })
    );
  }
  public deactivate(id: string): Observable<IEnvelope<IEngine>> {
    return this.apiEngineService.deactivated(id).pipe(
      map((res: IEnvelope<IEngine>) => {
        if (res.item) {
          const index = this.engines$.value.findIndex((item) => item.id === id);
          if (index > -1) {
            this.engines$.value[index] = res.item;
            this.engines$.next(this.engines$.value);
          }
        }
        return res;
      })
    );
  }
  public activate(id: string): Observable<IEnvelope<IEngine>> {
    return this.apiEngineService.activated(id).pipe(
      map((res: IEnvelope<IEngine>) => {
        if (res.item) {
          const index = this.engines$.value.findIndex((item) => item.id === id);
          if (index > -1) {
            this.engines$.value[index] = res.item;
            this.engines$.next(this.engines$.value);
          }
        }
        return res;
      })
    );
  }
  // #endregion Constructors (1)
}
