import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QRCodeModule } from 'angularx-qrcode';
import * as moment from 'moment';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SharedDirectivesModule } from './directives/shared-deirectives.module';
import { AuthInterceptorModule } from './interceptors/auth-interceptor.module';
import { CardMaskPipe } from './pipes/card.pipe';
import { CustomCurrencyPipe } from './pipes/currency.pipe';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { SpinnerModule } from '../app/routes/components/spinner/spinner.module';
import { DocTypeMaskPipe } from './pipes/doc-mask.pipe';
import { PhoneMaskPipe } from './pipes/phone-mask.pipe';
import { PostalCodeMaskPipe } from './pipes/zipcode.pipe';
moment.locale('pt-br');
export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
@NgModule({
  declarations: [
    PhoneMaskPipe,
    DocTypeMaskPipe,
    CustomCurrencyPipe,
    PostalCodeMaskPipe,
    CardMaskPipe,
  ],
  exports: [
    ReactiveFormsModule,
    SharedDirectivesModule,
    AuthInterceptorModule,
    ImageCropperModule,
    TranslateModule,
    QRCodeModule,
    SpinnerModule,
    PhoneMaskPipe,
    DocTypeMaskPipe,
    CustomCurrencyPipe,
    PostalCodeMaskPipe,
    CardMaskPipe,
  ],
  imports: [
    ReactiveFormsModule,
    AuthInterceptorModule,
    SharedDirectivesModule,
    ImageCropperModule,
    QRCodeModule,
    MomentModule,
    SpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    PhoneMaskPipe,
    DocTypeMaskPipe,
    CustomCurrencyPipe,
    PostalCodeMaskPipe,
    CardMaskPipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SharedModule {}
