import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class LocalStorageCredentialsService {
  private key = 'pmb-credentials';

  constructor() {}

  public get value(): string {
    const val = localStorage.getItem(this.key);
    if (!val) {
      return '';
    }
    return val;
  }

  public set value(val: string) {
    if (!val) {
      localStorage.setItem(this.key, '');
    } else {
      localStorage.setItem(this.key, val);
    }
  }
}
