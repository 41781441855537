import {
  computed,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiMenuService } from '../../app/api/api-menu.service';
import { UpdateImageDto } from '../images/dto/update-image.dto';
import { CreateImportedMenuDto } from './dto/create-imported-menu.dto';
import { CreateMenuDto } from './dto/create-menu.dto';
import { DeleteImageDto } from './dto/delete-image-dto';
import { UpdateMenuDto } from './dto/update-menu.dto';
import { IMenu } from './interfaces/i-menu';

interface MenuImportState {
  [sourceId: string]: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MenusService {
  public filtered$: BehaviorSubject<IMenu[]> = new BehaviorSubject<IMenu[]>([]);
  public menus$: BehaviorSubject<IMenu[]> = new BehaviorSubject<IMenu[]>([]);
  public selectedMenu$: BehaviorSubject<IMenu | null> =
    new BehaviorSubject<IMenu | null>(null);

  constructor(private readonly apiMenuService: ApiMenuService) {}

  public getAll(
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IMenu>> {
    return this.apiMenuService.getAll(lastDocId, limit).pipe(
      tap((res: IEnvelopeArray<IMenu>) => {
        if (res?.items?.length) {
          this.menus$.next(res.items);
          this.filtered$.next(this.menus$.value);
        }
        return res;
      })
    );
  }
  public getAllMore(
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IMenu>> {
    return this.apiMenuService.getAll(lastDocId, limit).pipe(
      tap((res: IEnvelopeArray<IMenu>) => {
        if (res?.items?.length) {
          this.menus$.next([...this.menus$.value, ...res.items]);
          this.filtered$.next(this.menus$.value);
        }
        return res;
      })
    );
  }
  public getByTag(
    tag: string,
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IMenu>> {
    return this.apiMenuService.getByTag(tag, lastDocId, limit).pipe(
      tap((res: IEnvelopeArray<IMenu>) => {
        this.filtered$.next(res.items);
        return res;
      })
    );
  }

  public findBySourceId(sourceId: string): Observable<IEnvelope<IMenu>> {
    return this.apiMenuService.findBySourceId(sourceId).pipe(
      map((res: IEnvelope<IMenu>) => {
        this.selectedMenu$.next(res.item);
        return res;
      })
    );
  }

  public createMenu(obj: CreateMenuDto): Observable<IEnvelope<IMenu>> {
    return this.apiMenuService.createMenu(obj).pipe(
      map((res: IEnvelope<IMenu>) => {
        const list = this.menus$.value;
        if (res.item) {
          list.push(res.item as IMenu);
        }
        this.menus$.next(list);
        this.filtered$.next(list);
        return res;
      })
    );
  }

  public createImportedMenu(
    obj: CreateImportedMenuDto
  ): Observable<IEnvelope<IMenu>> {
    return this.apiMenuService.createImportedMenu(obj).pipe(
      map((res: IEnvelope<IMenu>) => {
        const list = this.menus$.value;
        if (res.item) {
          list.push(res.item as IMenu);
        }
        this.menus$.next(list);
        this.filtered$.next(list);
        return res;
      })
    );
  }

  public getByIdWithSections(menuId: string): Observable<IEnvelope<IMenu>> {
    return this.apiMenuService.getByIdWithSections(menuId).pipe(
      map((res: IEnvelope<IMenu>) => {
        this.selectedMenu$.next(res.item);
        return res;
      })
    );
  }

  public updateMenu(
    menuId: string,
    menu: UpdateMenuDto
  ): Observable<IEnvelope<IMenu>> {
    return this.apiMenuService.updateMenu(menuId, menu).pipe(
      map((res: IEnvelope<IMenu>) => {
        const index = this.menus$.value.findIndex((x) => x.id === menuId);
        this.menus$.value[index] = res.item as IMenu;
        this.menus$.next(this.menus$.value);
        this.selectedMenu$.next(null);
        return res;
      })
    );
  }

  public updateImage(
    menuId: string,
    data: UpdateImageDto
  ): Observable<IEnvelope<IMenu>> {
    return this.apiMenuService.updateImage(menuId, data).pipe(
      map((res: IEnvelope<IMenu>) => {
        const index = this.menus$.value.findIndex((x) => x.id === menuId);
        this.menus$.value[index] = res.item as IMenu;
        this.menus$.next(this.menus$.value);
        return res;
      })
    );
  }

  public deleteImage(
    menuId: string,
    obj: DeleteImageDto
  ): Observable<IEnvelope<IMenu>> {
    return this.apiMenuService.deleteImage(menuId, obj).pipe(
      map((res: IEnvelope<IMenu>) => {
        const index = this.menus$.value.findIndex((m) => m.id === menuId);
        this.menus$.value.splice(index, 1, res.item as IMenu);
        return res;
      })
    );
  }

  public delete(menuId: string): Observable<void> {
    return this.apiMenuService.delete(menuId).pipe(
      map(() => {
        const index = this.menus$.value.findIndex((m) => m.id === menuId);
        this.menus$.value.splice(index, 1);
        return;
      })
    );
  }

  public reset() {
    this.menus$.next([]);
    this.filtered$.next([]);
    this.selectedMenu$.next(null);
  }
}
