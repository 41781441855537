import {
  AfterViewInit,
  Directive,
  HostListener,
  Input,
  Optional,
} from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appPhoneNumberMask]',
})
export class PhoneNumberMaskDirective implements AfterViewInit {
  @Input('appPhoneNumberMask') input: any;
  pattern: string;
  constructor(@Optional() private model: NgModel) {}
  @HostListener('document:keydown', ['$event'])
  keyup(event: KeyboardEvent) {
    this.pattern = this.input?.getAttribute('mask');
    if (this.pattern) {
      this.onInputChange(event);
    }
  }
  ngAfterViewInit(): void {
    this.input = this.input.el;
  }

  onInputChange(e) {
    try {
      let value = e.target.value;
      const caret = e.target.selectionStart;
      const pattern = this.pattern;
      const reserve = pattern.replace(/\*/, 'g');
      let applied = '';
      let ordinal = 0;

      if (
        e.keyCode === 8 ||
        e.key === 'Backspace' ||
        e.keyCode === 46 ||
        e.key === 'Delete'
      ) {
        if (value.length) {
          //remove all trailing formatting
          while (
            value.length &&
            pattern[value.length] &&
            pattern[value.length] !== '*'
          ) {
            value = value.substring(0, value.length - 1);
          }
          //remove all leading formatting to restore placeholder
          if (pattern.substring(0, value.length).indexOf('*') < 0) {
            value = value.substring(0, value.length - 1);
          }
        }
      }

      //apply mask characters
      for (let i = 0; i < value.length; i++) {
        //enforce pattern limit
        if (i < pattern.length) {
          //match mask
          if (value[i] === pattern[ordinal]) {
            applied += value[i];
            ordinal++;
          } else if (reserve.indexOf(value[i]) > -1) {
            //skip other reserved characters
          } else {
            //apply leading formatting
            while (ordinal < pattern.length && pattern[ordinal] !== '*') {
              applied += pattern[ordinal];
              ordinal++;
            }
            applied += value[i];
            ordinal++;
            //apply trailing formatting
            while (ordinal < pattern.length && pattern[ordinal] !== '*') {
              applied += pattern[ordinal];
              ordinal++;
            }
          }
        }
      }
      e.target.value = applied;

      //console.log(this.model, applied)

      if (this.model && this.model.valueAccessor) {
        this.model.valueAccessor.writeValue(applied);
      }
      if (caret < value.length) {
        e.target.setSelectionRange(caret, caret);
      }
    } catch (ex) {
      console.error((ex as any).message);
    }
  }
}
