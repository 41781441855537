import { Injectable } from '@angular/core';
import { IEnvelope, IEnvelopeArray } from 'mobyo-interfaces';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiProductService } from 'src/app/api/api-products.service';
import { UpdateImageDto } from '../images/dto/update-image.dto';
import { IProduct } from './interfaces/i-product';
import { CreateProductDto } from './dto/create-product.dto';
import { UpdateProductDto } from './dto/update-product.dto';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  // #region Properties (4)

  public catalogProductsFiltered$: BehaviorSubject<IProduct[]> =
    new BehaviorSubject<IProduct[]>([]);
  public filtered$: BehaviorSubject<IProduct[]> = new BehaviorSubject<
    IProduct[]
  >([]);
  public products$: BehaviorSubject<IProduct[]> = new BehaviorSubject<
    IProduct[]
  >([]);
  public selectedProduct$: BehaviorSubject<IProduct | null> =
    new BehaviorSubject<IProduct | null>(null);

  // #endregion Properties (4)

  // #region Constructors (1)

  constructor(private readonly apiProductService: ApiProductService) {}

  // #endregion Constructors (1)

  // #region Public Methods (11)

  public catalogGetProductByTag(
    value: string,
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IProduct>> {
    return this.apiProductService.getByTag(value, lastDocId, limit).pipe(
      map((res: IEnvelopeArray<IProduct>) => {
        this.catalogProductsFiltered$.next(res.items);
        return res;
      })
    );
  }

  public changeStatus(productId: string): Observable<IEnvelope<IProduct>> {
    return this.apiProductService.changeStatus(productId).pipe(
      tap((res: IEnvelope<IProduct>) => {
        if (res.item) {
          this.updateProductSubject(res.item);
        }
        return res;
      })
    );
  }

  public create(obj: CreateProductDto): Observable<IEnvelope<IProduct>> {
    return this.apiProductService.create(obj).pipe(
      tap((res: IEnvelope<IProduct>) => {
        this.products$.value.push(res.item as IProduct);
        this.products$.next([...this.products$.value]);
        this.filtered$.next([...this.products$.value]);
        return res;
      })
    );
  }

  public delete(productId: string): Observable<void> {
    return this.apiProductService.delete(productId).pipe(
      map((res: void) => {
        const index = this.products$?.value?.findIndex(
          (x) => x.id === productId
        );
        if (index > -1) {
          this.products$.value?.splice(index, 1);
          this.products$.next(this.products$.value);
        }
        return res;
      })
    );
  }

  public getAll(
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IProduct>> {
    return this.apiProductService.getAll(lastDocId, limit).pipe(
      tap((res: IEnvelopeArray<IProduct>) => {
        if (res?.items?.length) {
          this.products$.next(res.items);
          this.filtered$.next(this.products$.value);
        }
        return res;
      })
    );
  }

  public getAllBySkill(
    skill: string,
    lastDocId: string | null,
    limit: number
  ): Observable<IEnvelopeArray<IProduct>> {
    return this.apiProductService.getAllBySkill(skill, lastDocId, limit).pipe(
      map((res: IEnvelopeArray<IProduct>) => {
        const ref = this.products$.value;
        res.items = _.orderBy(res.items, ['index'], ['asc']);
        ref.push(...res.items);
        this.products$.next(ref);
        return res;
      })
    );
  }

  public getAllMore(
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IProduct>> {
    return this.apiProductService.getAll(lastDocId, limit).pipe(
      tap((res: IEnvelopeArray<IProduct>) => {
        if (res?.items?.length) {
          this.products$.next([...this.products$.value, ...res.items]);
          this.filtered$.next(this.products$.value);
        }
        return res;
      })
    );
  }

  public getByCode(value: string): Observable<IEnvelopeArray<IProduct>> {
    return this.apiProductService.getByCode(value).pipe(
      map((res: IEnvelopeArray<IProduct>) => {
        const ref = this.products$.value;
        res.items = _.orderBy(res.items, ['index'], ['asc']);
        ref.push(...res.items);
        this.products$.next(ref);
        return res;
      })
    );
  }

  public getAllByCodes(codes: string[]): Observable<IEnvelopeArray<any>> {
    return this.apiProductService.getAllByCodes(codes).pipe(
      tap((res: IEnvelopeArray<any>) => res)
    );
  }

  public getByTag(
    tag: string,
    lastDocId: string | null,
    limit: string
  ): Observable<IEnvelopeArray<IProduct>> {
    return this.apiProductService.getByTag(tag, lastDocId, limit).pipe(
      tap((res: IEnvelopeArray<IProduct>) => {
        this.filtered$.next(res.items);
        return res;
      })
    );
  }

  public update(
    productId: string,
    obj: UpdateProductDto
  ): Observable<IEnvelope<IProduct>> {
    return this.apiProductService.update(productId, obj).pipe(
      tap((res: IEnvelope<IProduct>) => {
        if (res.item) {
          this.updateProductSubject(res.item);
        }
        return res;
      })
    );
  }

  public updateImage(
    productId: string,
    obj: UpdateImageDto
  ): Observable<IEnvelope<IProduct>> {
    return this.apiProductService.updateImage(productId, obj).pipe(
      tap((res: IEnvelope<IProduct>) => {
        if (res.item) {
          this.updateProductSubject(res.item);
          this.selectedProduct$.next(res.item);
        }
        return res;
      })
    );
  }

  public updateProductSubject(
    reference: IProduct | IProduct[] | null,
    shouldCreate = false
  ) {
    if (!reference) return;

    if (Array.isArray(reference)) {
      reference.forEach((item) => {
        const index = this.products$.value.findIndex((x) => x.id === item.id);
        if (index > -1) {
          this.products$.value[index] = item;
        } else if (shouldCreate) {
          this.products$.value.push(item);
        }

        const indexFiltered = this.filtered$.value.findIndex(
          (x) => x.id === item.id
        );
        if (indexFiltered > -1) {
          this.filtered$.value[indexFiltered] = item;
        } else if (shouldCreate) {
          this.filtered$.value.push(item);
        }
      });
    } else {
      const index = this.products$.value.findIndex(
        (x) => x.id === reference.id
      );
      if (index > -1) {
        this.products$.value[index] = reference;
      } else if (shouldCreate) {
        this.products$.value.push(reference);
      }

      const indexFiltered = this.filtered$.value.findIndex(
        (x) => x.id === reference.id
      );
      if (indexFiltered > -1) {
        this.filtered$.value[indexFiltered] = reference;
      } else if (shouldCreate) {
        this.filtered$.value.push(reference);
      }
    }

    this.products$.next(this.products$.value);
    this.filtered$.next(this.filtered$.value);
  }

  public reset() {
    this.catalogProductsFiltered$.next([]);
    this.filtered$.next([]);
    this.products$.next([]);
    this.selectedProduct$.next(null);
  }

  // #endregion Public Methods (11)
}
