// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiUrl: 'https://api.mobyo.com.br',
  // apiUrl: 'http://localhost:8080',
  // apiUrl: 'http://192.168.15.31:8080',
  // apiUrl: 'http://192.168.0.27:8080',
  // apiUrl: 'http://192.168.0.249:8080',

  desenfilaPaymentId: 'paQUVKhfFiTNCxxhhmDE',
  accountId: 'D39D47AA36744D669A9D2458CAFA8A48',
  mobyoAppId: '170965920119D3F18369CE6E17ABEC5E',
  mobyoSecrets:
    '06cdca336b1bd9f8233a1988799279981ee7cbdc0a02518ea5f20f724d7d0f15',
  digitalMenuUrl: 'https://comanda.mobyo.com.br',
  firebaseConfig: {
    apiKey: 'AIzaSyC-m9nky5iYuP9Cpulz2Iyvr8Gdf4yzcwk',
    authDomain: 'mobyo-447719.firebaseapp.com',
    databaseURL: 'https://mobyo-447719-default-rtdb.firebaseio.com',
    projectId: 'mobyo-447719',
    storageBucket: 'mobyo-447719.firebasestorage.app',
    messagingSenderId: '994931846320',
    appId: '1:994931846320:web:3f0fa89c1d115091a3df75',
    measurementId: 'G-4M6XW11WVN',
  },
  recaptchaKey: '6Lfuxb8kAAAAAIAeXSu3o-Wxtx4xCsOagM7nlhsv',
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
