import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ERouters } from '../shared/utils/routers-enum';
import { companyGuard } from './guards/company.guard';
import { payloadGuard } from './guards/payload.guard';
import { toHomeGuard } from './guards/to-home.guard';
import { tokenGuard } from './guards/token.guard';
import { LayoutPage } from './layout/layout.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: ERouters.app,
    pathMatch: 'full',
  },
  {
    path: ERouters.login,
    canActivateChild: [toHomeGuard],
    loadChildren: () =>
      import('./routes/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: ERouters.checkin,
    canActivateChild: [tokenGuard],
    loadChildren: () =>
      import('./routes/checkin/checkin.module').then(
        (m) => m.CheckinPageModule
      ),
  },
  {
    path: ERouters.register,
    canActivate: [],
    loadChildren: () =>
      import('./routes/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: ERouters.createCompany,
    loadChildren: () =>
      import('./routes/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: ERouters.app,
    canActivateChild: [payloadGuard, companyGuard],
    component: LayoutPage,
    children: [
      {
        path: '',
        redirectTo: ERouters.home,
        pathMatch: 'full',
      },
      {
        path: ERouters.home,
        canActivate: [],
        loadChildren: () =>
          import('./routes/home/home.module').then((m) => m.HomePageModule),
      },
      {
        path: ERouters.members,
        canActivate: [],
        loadChildren: () =>
          import('./routes/members/members.module').then(
            (m) => m.MembersPageModule
          ),
      },

      {
        path: ERouters.engines,
        canActivate: [],
        loadChildren: () =>
          import('./routes/engines/engines.module').then(
            (m) => m.EnginesPageModule
          ),
      },
      {
        path: ERouters.devices,
        canActivate: [],
        loadChildren: () =>
          import('./routes/devices/devices.module').then(
            (m) => m.DevicesPageModule
          ),
      },
      {
        path: ERouters.settings,
        canActivate: [],
        loadChildren: () =>
          import('./routes/settings/settings.module').then(
            (m) => m.SettingsPageModule
          ),
      },
      {
        path: ERouters.products,
        canActivate: [],
        loadChildren: () =>
          import('./routes/products/products.module').then(
            (m) => m.ProductsPageModule
          ),
      },
      {
        path: ERouters.vouchers,
        canActivate: [],
        loadChildren: () =>
          import('./routes/vouchers/vouchers.module').then(
            (m) => m.VouchersPageModule
          ),
      },
      {
        path: ERouters.toppings,
        canActivate: [],
        loadChildren: () =>
          import('./routes/toppings/toppings.module').then(
            (m) => m.ToppingsPageModule
          ),
      },
      {
        path: ERouters.questions,
        canActivate: [],
        loadChildren: () =>
          import('./routes/questions/questions.module').then(
            (m) => m.QuestionsPageModule
          ),
      },
      {
        path: ERouters.menus,
        canActivate: [],
        loadChildren: () =>
          import('./routes/menus/menus.module').then((m) => m.MenusPageModule),
      },
      {
        path: ERouters.images,
        canActivate: [],
        loadChildren: () =>
          import('./routes/images/images.module').then(
            (m) => m.ImagesPageModule
          ),
      },
      {
        path: ERouters.configs,
        canActivate: [],
        loadChildren: () =>
          import('./routes/configs/configs.module').then(
            (m) => m.ConfigsModule
          ),
      },
      {
        path: ERouters.myAccount,
        loadChildren: () =>
          import('./routes/my-account/my-account.module').then(
            (m) => m.MyAccountPageModule
          ),
      },
      {
        path: ERouters.checkout,
        loadChildren: () =>
          import('./routes/checkout/checkout.module').then(
            (m) => m.CheckoutPageModule
          ),
      },
      {
        path: ERouters.customers,
        loadChildren: () =>
          import('./routes/customers/customers.module').then(
            (m) => m.CustomersPageModule
          ),
      },
      {
        path: ERouters.report_deliver,
        loadChildren: () => import('./routes/report-delivery/report-delivery.module').then( m => m.ReportDeliveryPageModule)
      },
    ],
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./routes/terms-of-use/terms-of-use.module').then(
        (m) => m.TermsOfUsePageModule
      ),
  },
  { path: '**', redirectTo: `${ERouters.app}/${ERouters.home}` },  {
    path: 'deliverers',
    loadChildren: () => import('./routes/deliverers/deliverers.module').then( m => m.DeliverersPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
