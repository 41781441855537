import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AlertController,
  LoadingController,
  ToastController,
  ViewDidEnter,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  EImageFolder,
  EMimeTypeFile,
  IMember,
  EMemberRules,
} from 'mobyo-interfaces';
import { UploadFileDto } from '../../../../services/images/dto/upload-file.dto';
import { ImagesService } from '../../../../services/images/images.service';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { ICropperConfig } from '../cropper/interfaces/i-cropper-config';

@Component({
  selector: 'app-list-images',
  templateUrl: './list-images.component.html',
  styleUrls: ['./list-images.component.scss'],
})
export class ListImagesComponent
  extends BaseComponent
  implements OnInit, ViewDidEnter
{
  // #region Properties (19)

  @Input() public component: boolean = true;
  public eTypeDefault = EImageFolder.DEFAULT;
  public eUser = EMemberRules.USER;
  public filePreview: string | ArrayBuffer | null = null;
  public fileType: string | null = null;
  public filteredFiles: any = null;
  @Input() public referenceObj: any = null;
  @Input() public folderType: EImageFolder;
  @Input() public config: ICropperConfig;
  public isBusy: boolean = false;
  public isCropping: boolean = false;
  public isLoading: boolean = false;
  public limit = 6;
  @Output() public mediaPath = new EventEmitter<{
    url?: string;
    referenceObj?: any;
    path: string;
  }>();
  public nextPageToken: string = '';
  @Input() public payload: IMember | null = null;
  public selectedFile: File | null = null;
  public types: string[] = Object.values(EImageFolder).filter(
    (type) => type !== this.eTypeDefault
  );
  public uploadType: 'image' | 'video' = 'image';
  public uploading: boolean = false;

  // #endregion Properties (19)

  // #region Constructors (1)

  constructor(
    public readonly imagesService: ImagesService,
    private translateService: TranslateService,
    toastController: ToastController,
    alertController: AlertController,
    loadingController: LoadingController
  ) {
    super(toastController, alertController, loadingController);
    this.translateService.addLangs(['en', 'pt-BR']);
    this.translateService.setDefaultLang('pt-BR');
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(
      browserLang?.match(/en|pt-BR/) ? browserLang : 'pt-BR'
    );

    effect(() => {
      const newValue = this.imagesService.files;
      // console.log('Files newValue', newValue);
      if (newValue) {
        this.filteredFiles = newValue.files.items;
        this.nextPageToken = newValue.nextPageToken || '';
      }
    });
  }

  // #endregion Constructors (1)

  // #region Public Methods (10)

  public accessFolder(type: EImageFolder) {
    this.folderType = type;
    this.onGetFiles();
  }

  public convertFileTypeToEnum(): EMimeTypeFile | null {
    switch (this.fileType) {
      case 'image/png':
        return EMimeTypeFile.PNG;
      case 'image/jpeg':
        return EMimeTypeFile.JPEG;
      case 'video/mp4':
        return EMimeTypeFile.MPFOUR;
      case 'video/ogg':
        return EMimeTypeFile.OGG;
    }

    return null;
  }

  public getFileType(): string {
    if (this.fileType) {
      if (this.fileType.startsWith('image/')) {
        return 'Imagem';
      } else if (this.fileType.startsWith('video/')) {
        return 'Vídeo';
      } else {
        return 'Arquivo desconhecido';
      }
    }
    return 'Nenhum arquivo selecionado';
  }

  public ionViewDidEnter(): void {
    this.isCropping = false;
  }

  public ngOnInit() {
    this.onGetColls();
  }

  public clearMedia() {
    this.selectedFile = null;
    this.fileType = null;
    this.filePreview = null;
  }

  public onFactoryCropperConfig(
    x: number,
    y: number,
    quality: number,
    aspectRatio: number
  ): ICropperConfig {
    if (this.config) {
      return this.config;
    }

    return {
      x,
      y,
      quality,
      aspectRatio,
    };
  }

  public goToUpload(type) {
    this.clearMedia();
    this.uploadType = type;
    this.isCropping = true;
  }

  public onFileSelected($e: any) {
    try {
      // console.log('onFileSelected $e', $e, 'uploadType', this.uploadType);
      switch (this.uploadType) {
        case 'image':
          // console.log('Seguindo o fluxo em caso de imagem!');
          this.selectedFile = $e;
          this.fileType = $e.type;
          if (!this.selectedFile || !this.fileType) {
            // console.log('Nenhum arquivo selecionado.');
            return;
          }

          // Define um tamanho máximo de 50MB
          const MAX_SIZE = 50 * 1024 * 1024;
          if (this.selectedFile.size > MAX_SIZE) {
            // Exiba uma mensagem de erro se o tamanho do arquivo exceder o limite
            this.alert(
              'O tamanho do arquivo excede o limite de 50MB.',
              'Oops!'
            );
            this.selectedFile = null;
            return;
          }
          if (['image/png', 'image/jpeg'].includes(this.fileType as string)) {
            this.onUploadMedia();
            return;
          }
          this.toast(
            'Tipo de arquivo não permitido. Selecione uma imagem PNG/JPEG ou um vídeo MP4/OGG',
            'Oops!',
            'danger',
            'top'
          );
          this.clearMedia();
          return;
        case 'video':
          // console.log('Seguindo o fluxo de VÍDEO');
          const input = $e.target as HTMLInputElement;
          // console.log('input files', input.files);
          if (input.files && input.files.length > 0) {
            const file = input.files[0];

            // Define um tamanho máximo de 50MB
            const MAX_SIZE = 50 * 1024 * 1024;
            // console.log(
            //   'Tamanho do arquivo & tamanho máximo',
            //   file.size,
            //   MAX_SIZE
            // );
            if (file.size > MAX_SIZE) {
              // Exiba uma mensagem de erro se o tamanho do arquivo exceder o limite
              this.alert(
                'O tamanho do arquivo excede o limite de 50MB.',
                'Oops!'
              );
              this.selectedFile = null;
              return;
            }

            // Define uma duração máxima de 15 segundos
            const MAX_DURATION = 15;
            // console.log(
            //   'O tipo do vídeo é',
            //   file.type,
            //   'a duração máxima é de',
            //   MAX_DURATION
            // );
            if (['video/mp4', 'video/ogg'].includes(file.type as string)) {
              const videoURL = URL.createObjectURL(file);
              const video = document.createElement('video');

              video.preload = 'metadata';
              video.src = videoURL;
              video.onloadedmetadata = () => {
                URL.revokeObjectURL(videoURL);
                const duration = video.duration;

                if (duration > MAX_DURATION) {
                  alert(
                    `A duração do vídeo excede o limite de ${MAX_DURATION} segundos.`
                  );
                  this.selectedFile = null;
                  return;
                }

                this.selectedFile = file;
                this.fileType = this.selectedFile?.type || null;

                this.loadPreview(file);
              };

              video.onloadeddata = () => {
                this.loadPreview(this.selectedFile as File);
              };

              video.onerror = () => {
                alert(
                  'Não foi possível carregar o vídeo. Por favor, selecione um arquivo válido.'
                );
                this.selectedFile = null;
              };
              return;
            }
            this.toast(
              'Tipo de arquivo não permitido. Selecione uma imagem PNG/JPEG ou um vídeo MP4/OGG',
              'Oops!',
              'danger',
              'top'
            );
            this.clearMedia();
            return;
          }
          this.toast('Nenhum arquivo encontrado', 'Oops!', 'danger', 'top');
          this.clearMedia();
          return;
      }
    } catch (error: any) {
      this.uploading = false;
      console.error(error);
      this.alert(error?.message, 'Oops!');
    }
  }

  private loadPreview(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.filePreview = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  public onUploadMedia() {
    if (!this.selectedFile || !this.fileType) {
      return;
    }
    this.uploading = true;
    let type: EImageFolder | string = this.folderType;
    if (this.folderType === EImageFolder.PRODUCTS && this.referenceObj) {
      type = `${EImageFolder.PRODUCTS}/${this.referenceObj.code}`;
    }
    if (this.folderType === EImageFolder.COMPOSITIONS && this.referenceObj) {
      type = `${EImageFolder.COMPOSITIONS}/${this.referenceObj.code}`;
    }
    if (this.folderType === EImageFolder.MEMBERS) {
      let id = this.referenceObj?.userId;
      if (!id) {
        id = this.referenceObj?.id;
      }
      type = `${EImageFolder.MEMBERS}/${id}`;
    }
    const data = {
      folderPath: type,
      fileName: this.selectedFile?.name,
      mimeType: this.convertFileTypeToEnum(),
    } as UploadFileDto;
    // console.log('data', data, 'type', type, 'this.type', this.folderType);
    const formData: FormData = new FormData();
    // Anexa o arquivo ao FormData
    formData.append('file', this.selectedFile, this.selectedFile.name);
    // Anexa os dados adicionais como uma string JSON
    formData.append('data', JSON.stringify(data));

    this.imagesService.uploadMedia(formData).subscribe({
      next: (_) => {
        this.toast(
          'Arquivo enviado com sucesso!',
          'Sucesso!',
          'success',
          'top'
        );
        this.clearMedia();
        this.uploading = false;
        this.isCropping = false;
        this.onGetFiles();
      },
      error: (err) => {
        this.isCropping = false;
        this.uploading = false;
        console.error(err);
        if (!err?.message && !err.status) {
          err.message =
            'Houve um erro ao enviar seu arquivo. Tente novamente mais tarde.';
        }
        this.alert(err?.message, 'Oops!');
      },
    });
  }

  public onGetMoreFiles() {
    if (!this.nextPageToken) {
      return;
    }
    this.isLoading = true;
    let type: EImageFolder | string = this.folderType;
    if (this.folderType === EImageFolder.DEFAULT) {
      type = '';
    }
    if (this.folderType === EImageFolder.PRODUCTS && this.referenceObj) {
      type = `${EImageFolder.PRODUCTS}/${this.referenceObj.code}`;
    }
    this.imagesService
      .getMoreFiles(type, this.nextPageToken, this.limit)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          console.error(err);
          this.alert(err?.message, 'Oops!');
        },
      });
  }

  public selectMedia(image: any) {
    if (!this.component) {
      return;
    }
    const data = {
      path: image?.path,
      referenceObj: this.referenceObj || null,
      url: image?.url,
    };
    return this.mediaPath.emit(data);
  }

  // #endregion Public Methods (10)

  // #region Private Methods (3)

  private onGetColls(): void {
    this.onGetFiles();
  }

  private onGetFiles() {
    this.isBusy = true;
    let type: EImageFolder | string = this.folderType;
    if (this.folderType === EImageFolder.DEFAULT) {
      type = '';
    }
    if (this.folderType === EImageFolder.PRODUCTS && this.referenceObj) {
      type = `${EImageFolder.PRODUCTS}/${this.referenceObj.code}`;
    }
    if (this.folderType === EImageFolder.MEMBERS) {
      let id = this.referenceObj?.userId;
      if (!id) {
        id = this.referenceObj?.id;
      }
      type = `${EImageFolder.MEMBERS}/${id}`;
    }
    // console.log('onGetFiles', type);
    this.imagesService.getFiles(type, '', this.limit).subscribe({
      next: (res) => {
        this.isBusy = false;
      },
      error: (err) => {
        this.isBusy = false;
        console.error(err);
        this.alert(err?.message, 'Oops!');
      },
    });
  }

  // #endregion Private Methods (3)
}
