import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalSelectImageComponent } from './modal-select-image.component';
import { SharedModule } from '../../../../shared/shared.module';
import { ListImagesModule } from '../list-images/list-images.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    ListImagesModule,
  ],
  declarations: [ModalSelectImageComponent],
  exports: [ModalSelectImageComponent],
})
export class ModalSelectImageModule {}
