import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { ResetService } from 'src/services/reset/reset.service';
import { VersionService } from '../../../services/version.service';
import { BaseComponent } from '../../../shared/utils/base.component';
import { ERouters } from '../../../shared/utils/routers-enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  // #region Properties (3)

  public isBusy = false;
  public version = '0.0.0';

  // #endregion Properties (3)

  // #region Constructors (1)

  constructor(
    private readonly router: Router,
    private versionService: VersionService,
    private readonly resetService: ResetService,
    toastController: ToastController,
    alertController: AlertController,
    loadingController: LoadingController
  ) {
    super(toastController, alertController, loadingController);
  }

  // #endregion Constructors (1)

  // #region Public Methods (3)



  public ngOnInit() {
    this.versionService.getVersion().subscribe((packageJson) => {
      this.version = packageJson.version;
    });
  }

  public async onLogout(): Promise<void> {
    this.isBusy = true;
    const loader = await this.loadingShow('Saindo do aplicativo... Aguarde!');
    try {
      this.resetService.resetAllServices();
      this.isBusy = false;
      await loader.dismiss();
      await this.router.navigate([ERouters.login]);
    } catch (error: any) {
      this.isBusy = false;
      await loader.dismiss();
      await this.alert(error.message, 'Ops!');
    }
  }

  // #endregion Public Methods (3)
}
