import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AlertController,
  IonModal,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { EImageFolder } from 'mobyo-interfaces';
import { BaseComponent } from '../../../../shared/utils/base.component';

@Component({
  selector: 'app-modal-select-image',
  templateUrl: './modal-select-image.component.html',
  styleUrls: ['./modal-select-image.component.scss'],
})
export class ModalSelectImageComponent extends BaseComponent implements OnInit {
  public eProductType = EImageFolder.PRODUCTS;
  @Input() public folderType: EImageFolder;
  @Input() public cropperConfig: any;
  public referenceObj: any = null;
  @Output() public mediaPath = new EventEmitter<{
    url?: string;
    referenceObj?: any;
    path: string;
  }>();
  @ViewChild(IonModal) public modal: IonModal;

  constructor(
    toastController: ToastController,
    alertController: AlertController,
    loadingController: LoadingController
  ) {
    super(toastController, alertController, loadingController);
  }

  ngOnInit() {}

  public onOpenModal(referenceObj: any) {
    this.referenceObj = referenceObj;
    this.modal.present();
  }
}
