// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected {
  border-left: 2px solid var(--ion-color-danger);
}

.selected ion-label {
  color: var(--ion-color-danger);
}

.selected ion-icon {
  color: var(--ion-color-danger);
}

.img-logo {
  max-width: 80%;
  margin: 0 auto;
  padding: 10px;
}

.img-svg {
  width: 24px;
  height: 24px;
  margin-right: 1.925rem;
}

.custom-toast {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  left: auto !important;
  width: auto;
  max-width: 90%;
  margin: 0 !important;
  animation: slideInRight 0.5s ease-out;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.custom-toast-dismiss {
  animation: slideOutRight 0.5s ease-in;
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
.importing-footer {
  margin-top: auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/sidebar/sidebar.component.scss"],"names":[],"mappings":"AAAA;EACE,8CAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,cAAA;EACA,cAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;AACF;;AAEA;EACE,0BAAA;EACA,YAAA;EACA,WAAA;EACA,qBAAA;EACA,WAAA;EACA,cAAA;EAEA,oBAAA;EAEA,qCAAA;AADF;;AAIA;EACE;IACE,2BAAA;EADF;EAGA;IACE,wBAAA;EADF;AACF;AAIA;EACE,qCAAA;AAFF;;AAKA;EACE;IACE,wBAAA;EAFF;EAIA;IACE,2BAAA;EAFF;AACF;AAKA;EACE,gBAAA;EACA,eAAA;EAEA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAJF","sourcesContent":[".selected {\r\n  border-left: 2px solid var(--ion-color-danger);\r\n}\r\n\r\n.selected ion-label {\r\n  color: var(--ion-color-danger);\r\n}\r\n\r\n.selected ion-icon {\r\n  color: var(--ion-color-danger);\r\n}\r\n\r\n.img-logo {\r\n  max-width: 80%;\r\n  margin: 0 auto;\r\n  padding: 10px;\r\n}\r\n\r\n.img-svg {\r\n  width: 24px;\r\n  height: 24px;\r\n  margin-right: 1.925rem;\r\n}\r\n\r\n.custom-toast {\r\n  position: fixed !important;\r\n  bottom: 20px;\r\n  right: 20px;\r\n  left: auto !important;\r\n  width: auto;\r\n  max-width: 90%;\r\n\r\n  margin: 0 !important;\r\n\r\n  animation: slideInRight 0.5s ease-out;\r\n}\r\n\r\n@keyframes slideInRight {\r\n  0% {\r\n    transform: translateX(100%);\r\n  }\r\n  100% {\r\n    transform: translateX(0);\r\n  }\r\n}\r\n\r\n.custom-toast-dismiss {\r\n  animation: slideOutRight 0.5s ease-in;\r\n}\r\n\r\n@keyframes slideOutRight {\r\n  0% {\r\n    transform: translateX(0);\r\n  }\r\n  100% {\r\n    transform: translateX(100%);\r\n  }\r\n}\r\n\r\n.importing-footer {\r\n  margin-top: auto;\r\n  font-size: 14px;\r\n\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
