import { Component, OnDestroy } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import {
  EDiscountType,
  EVoucherRuleType,
  EVoucherStatus,
  EVoucherTargetTypes,
  EMemberRules,
} from 'mobyo-interfaces';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';

// TODO: Add Angular decorator.
@Component({
  template: '',
})
export class BaseComponent implements OnDestroy {
  // #region Properties (19)

  protected subs: Subscription[] = [];
  public eVoucherEndAt = EVoucherRuleType.END_AT;
  public eVoucherFirstBuy = EVoucherRuleType.FIRST_BUY;
  public eVoucherInPaymentId = EVoucherRuleType.IN_PAYMENT_ID;
  public eVoucherMinValue = EVoucherRuleType.MIN_VALUE;
  public eVoucherOneByDay = EVoucherRuleType.ONE_BY_DAY;
  public eVoucherOneByUser = EVoucherRuleType.ONE_BY_USER;
  public eVoucherShiftEndAt = EVoucherRuleType.SHIFT_END_AT;
  public eVoucherShiftStartAt = EVoucherRuleType.SHIFT_START_AT;
  public eVoucherStartAt = EVoucherRuleType.START_AT;
  public eVoucherFixed = EDiscountType.FIXED_AMOUNT;
  public eVoucherPending = EVoucherStatus.PLACED;
  public eVoucherActive = EVoucherStatus.ACTIVE;
  public eVoucherPaused = EVoucherStatus.PAUSED;
  public eVoucherConcluded = EVoucherStatus.CONCLUDED;
  public preference: MediaQueryList = window.matchMedia(
    '(prefers-color-scheme: dark)'
  );

  // #endregion Properties (19)

  // #region Constructors (1)

  constructor(
    public readonly toastController: ToastController,
    public readonly alertController: AlertController,
    public readonly loadingController: LoadingController
  ) {}

  // #endregion Constructors (1)

  // #region Public Getters And Setters (5)

  public get countryCodes() {
    return [
      { name: 'Brasil', code: '+55' },
      { name: 'Estados Unidos', code: '+1' },
      // Adicione mais países conforme necessário
    ];
  }

  public get fillColors() {
    return [
      { name: 'Red' },
      { name: 'SeaGreen' },
      { name: 'DodgerBlue' },
      { name: 'DarkViolet' },
      { name: 'DimGray' },
      { name: 'Chocolate' },
      { name: 'DarkGreen' },
      { name: 'Orchid' },
      { name: 'RosyBrown' },
      { name: 'Slategray' },
      { name: 'Slategray' },
      { name: 'Slategray' },
      { name: 'Slategray' },
      { name: 'Slategray' },
      { name: 'Slategray' },
      { name: 'Slategray' },
    ];
  }

  public get internationalCodes() {
    return [
      { name: 'Brasil', code: '+55' },
      { name: 'Estados Unidos', code: '+1' },
      { name: 'Canadá', code: '+1' },
      { name: 'Reino Unido', code: '+44' },
      { name: 'Austrália', code: '+61' },
      { name: 'Alemanha', code: '+49' },
      { name: 'França', code: '+33' },
      { name: 'Japão', code: '+81' },
      { name: 'Índia', code: '+91' },
      { name: 'China', code: '+86' },
      { name: 'Russia', code: '+7' },
      { name: 'Portugal', code: '+351' },
      { name: 'Espanha', code: '+34' },
      { name: 'Itália', code: '+39' },

      // Adicione mais países conforme necessário
    ];
  }

  public get isDev() {
    return !environment.production;
  }

  public get ufList(): { value: string; label: string }[] {
    return [
      { value: 'AC', label: 'Acre' },
      { value: 'AL', label: 'Alagoas' },
      { value: 'AP', label: 'Amapá' },
      { value: 'AM', label: 'Amazonas' },
      { value: 'BA', label: 'Bahia' },
      { value: 'CE', label: 'Ceará' },
      { value: 'DF', label: 'Distrito Federal' },
      { value: 'ES', label: 'Espírito Santo' },
      { value: 'GO', label: 'Goiás' },
      { value: 'MA', label: 'Maranhão' },
      { value: 'MT', label: 'Mato Grosso' },
      { value: 'MS', label: 'Mato Grosso do Sul' },
      { value: 'MG', label: 'Minas Gerais' },
      { value: 'PA', label: 'Pará' },
      { value: 'PB', label: 'Paraíba' },
      { value: 'PR', label: 'Paraná' },
      { value: 'PE', label: 'Pernambuco' },
      { value: 'PI', label: 'Piauí' },
      { value: 'RJ', label: 'Rio de Janeiro' },
      { value: 'RN', label: 'Rio Grande do Norte' },
      { value: 'RS', label: 'Rio Grande do Sul' },
      { value: 'RO', label: 'Rondônia' },
      { value: 'RR', label: 'Roraima' },
      { value: 'SC', label: 'Santa Catarina' },
      { value: 'SP', label: 'São Paulo' },
      { value: 'SE', label: 'Sergipe' },
      { value: 'TO', label: 'Tocantins' },
    ];
  }

  // #endregion Public Getters And Setters (5)

  // #region Public Methods (7)

  public async alert(message: string, header: string, subHeader: string = '') {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header,
      subHeader,
      message,
      buttons: ['OK'],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
  }

  public async loadingShow(message: string = 'Processando... aguarde!') {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message,
      duration: 0,
      translucent: true,
      backdropDismiss: false,
    });
    await loading.present();
    return loading;
  }

  public ngOnDestroy(): void {
    // console.log('BaseComponent.ngOnDestroy');
    this.subs.forEach((it) => it.unsubscribe());
  }

  public onCopy(toCopy: string) {
    navigator.clipboard.writeText(toCopy);
    this.toast('Copiado para a área de transferência!', 'Sucesso!', 'success');
  }

  public onGetRuleColor(val: EMemberRules): string {
    switch (val) {
      case EMemberRules.ADMIN:
        return 'warning';
      case EMemberRules.USER:
        return 'primary';
      case EMemberRules.OWNER:
        return 'success';
      case EMemberRules.SUPPORT:
        return 'quaternary';
      case EMemberRules.SALES:
        return 'danger';
      default:
        return 'medium';
    }
  }

  public onGetStatusColor(status: EVoucherStatus): string {
    switch (status) {
      case EVoucherStatus.PLACED:
        return 'medium';
      case EVoucherStatus.ACTIVE:
        return 'success';
      case EVoucherStatus.PAUSED:
        return 'primary-contrast';
      case EVoucherStatus.CONCLUDED:
        return 'danger';
      default:
        return 'primary';
    }
  }
  public onGetTargetTypeColor(targetType: EVoucherTargetTypes): string {
    switch (targetType) {
      case EVoucherTargetTypes.CART:
        return 'tertiary';
      case EVoucherTargetTypes.DELIVERY_FEE:
        return 'secondary';
      case EVoucherTargetTypes.ITEMS:
        return 'primary';
      case EVoucherTargetTypes.CATEGORY:
        return 'success';
      default:
        return 'medium';
    }
  }

  public onGetTargetTypeLabel(targetType: EVoucherTargetTypes): string {
    switch (targetType) {
      case EVoucherTargetTypes.CART:
        return 'Por Pedido';
      case EVoucherTargetTypes.DELIVERY_FEE:
        return 'Taxa de Entrega';
      case EVoucherTargetTypes.ITEMS:
        return 'Item';
      case EVoucherTargetTypes.CATEGORY:
        return 'Categoria de item';
      default:
        return targetType;
    }
  }

  public async toast(
    message: string,
    header: string,
    color: string = 'warning',
    position: 'bottom' | 'top' | 'middle' = 'bottom',
    duration: number = 2000
  ) {
    const toast = await this.toastController.create({
      header,
      message,
      position,
      color,
      duration,
    });
    return toast.present();
  }

  public validateCNPJ(cnpj: string): boolean {
    if (cnpj.length !== 14) return false;

    const digits = cnpj.split('').map((n) => parseInt(n, 10));

    let sum = 0;
    const weights1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    for (let i = 0; i < 12; i++) {
      sum += digits[i] * weights1[i];
    }
    let remainder = sum % 11;
    const firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;

    if (digits[12] !== firstCheckDigit) return false;

    sum = 0;
    const weights2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

    for (let i = 0; i < 13; i++) {
      sum += digits[i] * weights2[i];
    }
    remainder = sum % 11;
    const secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

    if (digits[13] !== secondCheckDigit) return false;

    return true;
  }

  public validateCPF(cpf: string): boolean {
    if (cpf.length !== 11) return false;

    const digits = cpf.split('').map((n) => parseInt(n, 10));

    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += digits[i] * (10 - i);
    }

    let remainder = sum % 11;
    const firstCheckDigit = remainder < 2 ? 0 : 11 - remainder;

    if (digits[9] !== firstCheckDigit) return false;

    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += digits[i] * (11 - i);
    }
    remainder = sum % 11;
    const secondCheckDigit = remainder < 2 ? 0 : 11 - remainder;

    if (digits[10] !== secondCheckDigit) return false;

    return true;
  }

  // #endregion Public Methods (7)
}
