import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertController,
  IonModal,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { EImageFolder, IMember } from 'mobyo-interfaces';
import { finalize, map, mergeMap } from 'rxjs';
import { ICompany } from 'src/services/company/interfaces/i-company';
import { MembersService } from 'src/services/members/members.service';
import { ERouters } from 'src/shared/utils/routers-enum';
import { AuthService } from '../../../../services/auth/auth.service';
import { AuthenticateDto } from '../../../../services/auth/dto/auth.dto';
import { CompanyService } from '../../../../services/company/company.service';
import { UpdateImageDto } from '../../../../services/images/dto/update-image.dto';
import { PayloadService } from '../../../../services/payload/payload.service';
import { ResetService } from '../../../../services/reset/reset.service';
import { LocalStorageCredentialsService } from '../../../../services/storage/local-credentials.service';
import { BaseComponent } from '../../../../shared/utils/base.component';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss'],
})
export class UserblockComponent extends BaseComponent implements OnInit {
  // #region Properties (7)

  public company: ICompany | null = null;
  public isBusy = false;
  public memberIn: IMember[] = [];
  @ViewChild(IonModal) public modalMemberIn: IonModal;
  public payload: IMember | null = null;
  public eFolderType = EImageFolder.MEMBERS;

  // #endregion Properties (7)

  // #region Constructors (1)

  constructor(
    private readonly payloadService: PayloadService,
    private readonly membersService: MembersService,
    private readonly companyService: CompanyService,
    private readonly authService: AuthService,
    private readonly localStorageCredentialsService: LocalStorageCredentialsService,
    private readonly resetService: ResetService,
    private readonly router: Router,
    toastController: ToastController,
    alertController: AlertController,
    loadingController: LoadingController
  ) {
    super(toastController, alertController, loadingController);
  }

  // #endregion Constructors (1)

  // #region Public Methods (5)

  public closeModal(): void {
    this.modalMemberIn.dismiss();
  }

  public getPayloadImage(): string {
    if (!this.payload?.photoUrl) {
      if (!(this.payload as any)?.photoURL) {
        return 'assets/imgs/avatar.png';
      }
      return (this.payload as any)?.photoURL;
    }
    return this.payload.photoUrl;
  }

  public ngOnInit() {
    this.subs.push(
      this.membersService.memberIn$.subscribe(
        (res: IMember[]) => (this.memberIn = res)
      ),
      this.payloadService.payload$.subscribe((res) => (this.payload = res)),
      this.companyService.company$.subscribe((res) => (this.company = res))
    );
  }

  public goToCheckin() {
    this.companyService.company$.next(null);
    this.resetService.resetAllServices();
    this.localStorageCredentialsService.value = '';
    this.router.navigate([ERouters.checkin], {
      replaceUrl: true,
    });
  }

  public onUpdateImage(data: { referenceObj?: any; path: string }): void {
    if (!data.referenceObj) {
      return;
    }
    this.isBusy = true;
    const obj = {
      path: data.path,
      field: 'photoURL',
      updatedBy: this.payload?.name || this.payload?.email || 'user',
    } as UpdateImageDto;
    let id = data.referenceObj?.uid;
    if (!id) {
      id = data.referenceObj?.id;
    }
    this.membersService.updateImage(id, obj).subscribe({
      next: (res) => {
        this.isBusy = false;
      },
      error: (e) => {
        this.isBusy = false;
        this.alert(e.message, 'Ops!');
      },
    });
  }

  public onSubmit(member: IMember): void {
    this.isBusy = true;
    const obj = new AuthenticateDto(member);
    this.authService
      .auth(obj)
      .pipe(
        map((res) => {
          if (member.companyId && member.containerId) {
            const val = `${member.containerId}@${member.companyId}`;
            this.localStorageCredentialsService.value = val;
          }
          return res;
        }),
        mergeMap(() => this.companyService.getCurrent())
      )
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (res) => {
          this.closeModal();
          this.router.navigate([ERouters.app + '/' + ERouters.home]);
        },
        error: (error) => {
          if (error.status === 0) {
            this.alert(
              'Sem conexão com o servidor, tente novamente mais tarde.',
              'Oops!',
              '[4A87]'
            );
            return;
          }

          this.alert(error.message, 'Oops 4A87');
        },
      });
  }

  // #endregion Public Methods (5)

  // #region Private Methods (1)

  // #endregion Private Methods (1)
}
