// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.container-options .divider {
  flex: 1;
  height: 1px;
  width: 100px;
  background-color: #999;
}
.container-options .or-text {
  margin: 0 10px;
  color: #999;
}`, "",{"version":3,"sources":["webpack://./src/app/routes/components/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACJ;AACI;EACI,OAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;AACR;AAEI;EACI,cAAA;EACA,WAAA;AAAR","sourcesContent":[".container-options {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin: 20px 0;\r\n\r\n    .divider {\r\n        flex: 1;\r\n        height: 1px;\r\n        width: 100px;\r\n        background-color: #999;\r\n    }\r\n\r\n    .or-text {\r\n        margin: 0 10px;\r\n        color: #999;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
