import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvelope } from 'mobyo-interfaces';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LinkProdcutSectionDto } from 'src/services/product-section/dto/link.dto';
import { UpdateProductSectionDto } from 'src/services/product-section/dto/update-product-section.dto';
import { UpdateProductsPositionsDto } from 'src/services/product-section/dto/update-products-positions.dto';
import { IProductSection } from 'src/services/product-section/interfaces/i-product-section';
import { ErrorHandler } from 'src/shared/middlewares/error.handler';

import { ApiBaseService } from './api-base.service';

@Injectable({
    providedIn: 'root'
})
export class ApiProductSectionService extends ApiBaseService {
    // #region Constructors (1)

    constructor(http: HttpClient) {
        super(http);
    }

    // #endregion Constructors (1)

    // #region Public Methods (2)

    public getById(menuId: string, sectionId: string, productId: string): Observable<IEnvelope<IProductSection>> {
        const apiURL = `${this.baseUrl}/v2/menus-products-sections/${menuId}/${sectionId}/${productId}/get-by-id`;
        return this.http.get<IEnvelope<IProductSection>>(apiURL).pipe(
            catchError(ErrorHandler.handlerError)
        );
    }

    public link(obj: LinkProdcutSectionDto): Observable<IEnvelope<IProductSection>> {
        const apiURL = `${this.baseUrl}/v2/menus-products-sections/link-product-to-section`;
        return this.http.post<IEnvelope<IProductSection>>(apiURL, obj).pipe(
            catchError(ErrorHandler.handlerError)
        );
    }
    public remove(menuId: string, sectionId: string, productId: string): Observable<void> {
        const apiURL = `${this.baseUrl}/v2/menus-products-sections/${menuId}/${sectionId}/${productId}/delete-of-section`;
        return this.http.delete<void>(apiURL).pipe(
            catchError(ErrorHandler.handlerError)
        );
    }
    public update(productId: string, obj: UpdateProductSectionDto): Observable<IEnvelope<IProductSection>> {
        const apiURL = `${this.baseUrl}/v2/menus-products-sections/${productId}/update`;
        return this.http.patch<IEnvelope<IProductSection>>(apiURL, obj).pipe(
            catchError(ErrorHandler.handlerError)
        );
    }
    public moveProducts(obj: UpdateProductsPositionsDto): Observable<void> {
        const apiURL = `${this.baseUrl}/v2/menus-products-sections/move-positions`;
        return this.http.patch<void>(apiURL, obj).pipe(
            catchError(ErrorHandler.handlerError)
        );
    }

    // #endregion Public Methods (2)
}
